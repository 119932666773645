import { handleActions } from 'redux-actions';
import {
  GET_FINDINGS_SUCCESS,
  GET_FINDINGS_FAILURE,
} from './targets.actions';

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  findings: null,
};

export default handleActions(
  {
    [GET_FINDINGS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        findings: payload,
      };
    },
    [GET_FINDINGS_FAILURE]: (state) => ({
      ...state,
      findings: null,
    }),
  },
  initialState,
);
