import { Button } from '@zaveit/uikit';

import useStyles from './Dashboard.styles';

function CoursesBanner({ title, subtitle, isSmall = false, buttonTitle = 'Explore courses' }) {
  const classes = useStyles({ isSmall });

  const handleNavigateTraining = () => {
    window.location.replace('https://docs.zaveit.io/');
  };

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.bannerTitle}>{title}</div>
      <div className={classes.bannerSubTitle}>{subtitle}</div>
      <div className={classes.exploreBtnContainer}>
        <Button styleClass="activeWhite" width="fit-content" onClick={handleNavigateTraining}>
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
}

export default CoursesBanner;
