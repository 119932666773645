// @ts-nocheck
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { Avatar, Tooltip } from '@zaveit/uikit';
import { Icon } from '@zaveit/icons';

import Score from 'components/shared/Score/Score';
import { PreviewCardData, PreviewCardPlaceholder } from 'components/constants';
import { TPreview } from 'components/types';

import { getTimeDifference } from './helpers';
import useStyles, { FlexBox } from './PreviewCard.styles';
import TypeComponent from './TypeComponent';
import PriorityComponent from './PriorityComponent';

type TPreviewItem = {
  itemData: TPreview;
  type: string;
  onItemClick?: (value: TPreview) => void;
  showService?: boolean;
  itemHeight?: string;
};

const PreviewItem: React.FC<TPreviewItem> = ({
  itemData,
  type,
  onItemClick,
  showService,
  itemHeight,
}) => {
  const classes = useStyles({ itemHeight });

  const date = itemData.updated;
  const time = useMemo(() => getTimeDifference(date), [date]);
  const fullName = useMemo(
    () => (itemData.firstname || itemData.lastname) && `${itemData.firstname} ${itemData.lastname}`,
    [itemData.firstname, itemData.lastname],
  );
  const title = useMemo(() => itemData.title || fullName, [itemData.title, fullName]);
  const subTitle = useMemo(
    () =>
      itemData.customer ||
      itemData.user ||
      itemData.target ||
      itemData.consumer ||
      itemData.description,
    [itemData.consumer, itemData.customer, itemData.target, itemData.user, itemData.description],
  );

  return (
    <Box
      className={classnames({
        [classes.itemContainer]: true,
        [classes.itemHover]: onItemClick,
      })}
      onClick={() => (onItemClick ? onItemClick(itemData) : () => {})}
    >
      <TypeComponent type={type} itemData={itemData} />
      {fullName && <Avatar fullName={fullName} />}
      {itemData.priority && <PriorityComponent priority={itemData.priority} />}
      <Box className={classes.titleContainer}>
        {title && (
          <Tooltip title={title}>
            <Typography className={classes.itemTitle}>{title}</Typography>
          </Tooltip>
        )}
        {subTitle && (
          <Tooltip title={subTitle}>
            <Typography className={classes.itemConsumer}>{subTitle}</Typography>
          </Tooltip>
        )}
      </Box>
      {itemData.service && showService && (
        <Box className={classes.serviceContainer}>
          <Tooltip title={itemData.service}>
            <Typography className={classes.itemText}>{itemData.service}</Typography>
          </Tooltip>
        </Box>
      )}
      {date && type !== PreviewCardPlaceholder.users.type && (
        <FlexBox className={classes.timeContainer}>
          <Icon iconName="schedule" className={classes.clockIcon} />
          <Typography className={classes.itemTime}>{time}</Typography>
        </FlexBox>
      )}
      {itemData.role && (
        <Typography className={classnames(classes.itemTitle, classes.lastItem)}>
          {itemData.role}
        </Typography>
      )}
      {PreviewCardData[type]?.hasRisc && (
        <Box className={classes.riscContainer}>
          <Score score={Number(itemData?.risc_score?.toFixed(1))} />
        </Box>
      )}
    </Box>
  );
};

PreviewItem.defaultProps = {
  onItemClick: null,
  showService: true,
};

export default PreviewItem;
