import ConsumerAdminDashboard from 'components/old-Dashboard/ConsumerAdminDashboard';
import StandardDashboard from 'components/old-Dashboard/StandartDashboard';
import { HomeViews } from 'helpers/handleGetHomeView';
import ProviderAdminView from './HomeProviderAdmin';

export default function Home({ view }) {
  if (view === HomeViews.CONSUMER_ADMIN_VIEW) {
    return <ConsumerAdminDashboard />;
  }

  if (view === HomeViews.PROVIDER_ADMIN_VIEW) {
    return <ProviderAdminView />;
  }

  return <StandardDashboard />;
}
