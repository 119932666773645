import low from 'static/svg/low.svg';
import lowest from 'static/svg/lowest.svg';
import medium from 'static/svg/medium.svg';
import high from 'static/svg/high.svg';
import highest from 'static/svg/highest.svg';
import tickets from 'static/svg/tickets.svg';
import findings from 'static/svg/findings.svg';
import customers from 'static/svg/customers.svg';
import targets from 'static/svg/targets.svg';
import risk from 'static/svg/risk.svg';
import { Features } from 'constants/features';

export const ServicesStatuses = {
  healthy: { status: 'healthy', text: 'All services online', icon: 'check_circle', color: 'green' },
  degraded: {
    status: 'degraded',
    text: 'Service issue detected',
    icon: 'warning',
    color: 'yellow',
  },
  down: { status: 'down', text: 'Service issue detected', icon: 'error', color: 'red' },
};

export const PreviewCardData = {
  findings: {
    name: 'findings',
    title: 'Findings',
    hasRisc: true,
    sortingTypes: [
      { name: 'Highest RISC', field: 'risc_score', isAsc: false },
      { name: 'Lowest RISC', field: 'risc_score', isAsc: true },
      { name: 'Oldest', field: 'updated', isAsc: true },
      { name: 'Latest', field: 'updated', isAsc: false },
    ],
  },
  support: {
    name: 'support',
    title: 'Support',
    hasRisc: false,
    sortingTypes: [
      { name: 'Latest', field: 'updated', isAsc: false },
      { name: 'Highest priority', filter: 'filter[priority]', filterValue: 'highest', isAsc: true },
      { name: 'High priority', filter: 'filter[priority]', filterValue: 'high', isAsc: true },
      {
        name: 'Moderate priority',
        filter: 'filter[priority]',
        filterValue: 'moderate',
        isAsc: true,
      },
      { name: 'Lowest priority', filter: 'filter[priority]', filterValue: 'lowest', isAsc: true },
      { name: 'Low priority', filter: 'filter[priority]', filterValue: 'low', isAsc: true },
      { name: 'Oldest', field: 'updated', isAsc: true },
    ],
  },
  customers: {
    name: 'customers',
    title: 'Customers',
    hasRisc: true,
  },
  users: {
    name: 'users',
    title: 'Users',
    hasRisc: false,
  },
  targets: {
    name: 'targets',
    title: 'Targets',
    hasRisc: true,
  },
};

export const PreviewCardPlaceholder = {
  findings: {
    type: 'findings',
    iconName: 'radar',
    title: 'No findings',
    description: 'When new findings are discovered they will appear here.',
  },
  support: {
    type: 'support',
    iconName: 'confirmation_number',
    title: 'No tickets',
    description: 'All open customer tickets will appear here.',
  },
  customers: {
    type: 'customers',
    iconName: 'store',
    title: 'No customers found',
    description: 'Once you have created your first customer, they will appear here.',
  },
  users: {
    type: 'users',
    iconName: 'person',
    title: 'No users added yet',
  },
  targets: {
    type: 'targets',
    iconName: 'gps_fixed',
    title: 'No targets added yet',
  },
  courses: {
    iconName: 'menu_book',
    title: 'No available courses',
    description: 'We couldn’t find any available training courses.',
  },
  supportAdmin: {
    iconName: 'confirmation_number',
    title: 'No open tickets',
    description: 'All open company tickets will appear here.',
  },
  supportStandart: {
    iconName: 'confirmation_number',
    title: 'Need help?',
  },
};

export const PreviewCardContent = {
  data: 'data',
  children: 'children',
  placeholer: 'placeholder',
};

export const PreviewItemIcons = {
  priority: {
    low,
    lowest,
    medium,
    moderate: medium,
    high,
    highest,
  },
};

export const TimeUnitsShortNames = {
  minute: 'm',
  hour: 'h',
  day: 'd',
  week: 'w',
};

export const Ranges = {
  score: {
    green: { min: 0, max: 3 },
    yellow: { min: 3, max: 6 },
    red: { min: 6, max: 10 },
  },
  tables: { min: 0, max: 5 },
};

export const StatisticsCards = [
  {
    title: 'RISC',
    icon: risk,
    key: 'risc',
    access: 'all',
    feature: Features.risc,
    tooltip:
      'We gather all risk factors in the system and give you the correlated risk for your company. RISC is short for Risk Index Score Correlated (0->10 highest)',
  },
  {
    title: 'Findings',
    icon: findings,
    key: 'findings',
    access: 'all',
    feature: Features.risc,
    path: '/findings',
    tooltip:
      'Findings are the source for RISC calculation. Findings are generated based on rules and ML in our service virtualization engine. Findings will have RISC from the specific issue.',
  },
  {
    title: 'Customers',
    icon: customers,
    key: 'tenants',
    access: 'provider',
    feature: Features.accountManagement,
    tooltip: 'Showing the total number of customers.',
  },
  {
    title: 'Targets',
    icon: targets,
    key: 'targets',
    access: 'consumer',
    feature: Features.risc,
    path: '/backup/targets',
  },
  {
    title: 'Open Tickets',
    icon: tickets,
    key: 'tickets',
    access: 'all',
    feature: Features.support,
    tooltip: 'Showing total Open Tickets in the system.',
  },
];

export const StatisticCardsAccess = {
  all: 'all',
  provider: 'provider',
  consumer: 'consumer',
};

export const ConsumptionIndexes = {
  prevWeek: 0,
  currDay: 1,
};

export const SliderConfig = {
  slides: 4,
};

export const DefaultSortingValue = -1;
