// @ts-nocheck
import React, { useState } from 'react';
import classnames from 'classnames';
import { Box } from '@material-ui/core';
import { Icon } from '@zaveit/icons';

import { PreviewCardPlaceholder } from 'components/constants';
import Config from 'config';

import useStyles from './PreviewCard.styles';
import { ApplicationImage } from './constants';

type TPreviewItem = {
  type: string;
  itemData: any;
};

const TypeComponent: React.FC<TPreviewItem> = ({ type, itemData }) => {
  const classes = useStyles();
  const [isImgError, setIsImgError] = useState<boolean>(false);
  const applicationImg = ApplicationImage[itemData?.metadata?.application];

  return (
    type === PreviewCardPlaceholder.findings.type && (
      <Box className={classnames(classes.itemIconContainer, classes.serviceIcon)}>
        {isImgError ? (
          <Icon
            iconName={PreviewCardPlaceholder.findings.iconName}
            className={classes.itemIconPlaceholder}
          />
        ) : (
          <img
            src={applicationImg ? `${Config.baseFileUrl}${applicationImg}` : ''}
            alt=""
            onError={() => setIsImgError(true)}
            onLoad={() => setIsImgError(false)}
          />
        )}
      </Box>
    )
  );
};

export default TypeComponent;
