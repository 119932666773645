import { Button as MuiButton } from '@material-ui/core';
import useStyles from './Button.styles';

type TButton = {
  children: any;
  onClick?: (values: any | undefined) => void | Promise<void>;
  styleClass: string;
  disabledClass?: string;
  disabled?: boolean;
  type?: any;
  width?: string;
  marginTop?: string;
  marginRight?: string;
  height?: string;
};
const Button = ({
  children,
  onClick,
  styleClass,
  disabled,
  disabledClass,
  type,
  width,
  height,
  marginTop,
  marginRight,
}: TButton) => {
  const classes = useStyles({ width, marginTop, height, marginRight });

  return (
    <MuiButton
      onClick={onClick}
      className={classes.rootBtn}
      classes={{
        root: classes[styleClass],
        disabled: classes[disabledClass],
      }}
      disabled={disabled}
      type={type}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  disabled: false,
  disabledClass: '',
  type: 'button',
  onClick: () => {},
  width: '',
  marginTop: '',
  height: '',
  marginRight: '',
};

export default Button;
