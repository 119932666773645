export enum TabsEnum {
  SUBSCRIPTION,
  PROPERTY,
}
export const tabs = ['Subscription', 'Property'];

export const rowsPerPageOptions = [5, 25, 50];

export const defaultSortModels = {
  property: {
    field: 'consumption',
    sort: 'desc',
  },
  subscription: {
    field: 'cost',
    sort: 'desc',
  },
  customer: {
    field: 'cost',
    sort: 'desc',
  },
};

export const tableNames = {
  property: 'dashboardApp_property',
  subscription: 'dashboardApp_subscription',
  customer: 'dashboardApp_customer',
};

export enum TimelinePeriodsEnum {
  CURRENT_MONTH = 'Current month',
  LAST_MONTH = 'Last month',
  THREE_MONTHS = '3 months',
  SIX_MONTHS = '6 months',
  YEAR_TO_DATE = 'Year to date',
  ONE_YEAR = '1 year',
  ALL_TIME = 'All time',
}

export const timelinePeriods = [
  'Current month',
  'Last month',
  '3 months',
  '6 months',
  'Year to date',
  '1 year',
  'All time',
];
