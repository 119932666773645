import { createActions } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------
export const GET_FINDINGS_DATA_REQUEST = 'GET_FINDINGS_DATA_REQUEST';
export const GET_FINDINGS_DATA_SUCCESS = 'GET_FINDINGS_DATA_SUCCESS';
export const GET_FINDINGS_DATA_FAILURE = 'GET_FINDINGS_DATA_FAILURE';
export const UPDATE_TABLE_FINDING = 'UPDATE_TABLE_FINDING';
export const CLEAR_FINDINGS_DATA = 'CLEAR_FINDINGS_DATA';

// ------------------------------------
// Synchronous Action Creators
// ------------------------------------

export const {
  getFindingsDataRequest,
  getFindingsDataSuccess,
  getFindingsDataFailure,
  updateTableFinding,
  clearFindingsData,
} = createActions(
  GET_FINDINGS_DATA_REQUEST,
  GET_FINDINGS_DATA_SUCCESS,
  GET_FINDINGS_DATA_FAILURE,
  UPDATE_TABLE_FINDING,
  CLEAR_FINDINGS_DATA,
);
