import { createActions } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------

export const SET_ACCOUNT_CREATION_STATUS = 'SET_ACCOUNT_CREATION_STATUS';
export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';
export const UPDATE_ACCOUNTS_LIST = 'UPDATE_ACCOUNTS_LIST';
export const SET_SELECTED_ACCOUNT_VIEW = 'SET_SELECTED_ACCOUNT_VIEW';
export const GET_SELECTED_VIEW_ACCOUNTS_REQUEST = 'GET_SELECTED_VIEW_ACCOUNTS_REQUEST';
export const GET_SELECTED_VIEW_ACCOUNTS_SUCCESS = 'GET_SELECTED_VIEW_ACCOUNTS_SUCCESS';
export const GET_SELECTED_VIEW_ACCOUNTS_FAILURE = 'GET_SELECTED_VIEW_ACCOUNTS_FAILURE';

// ------------------------------------
// Synchronous Action Creators
// ------------------------------------

export const {
  setAccountCreationStatus,
  getAccountsRequest,
  getAccountsSuccess,
  getAccountsFailure,
  updateAccountsList,
  setSelectedAccountView,
  getSelectedViewAccountsFailure,
  getSelectedViewAccountsSuccess,
  getSelectedViewAccountsRequest,
} = createActions(
  SET_ACCOUNT_CREATION_STATUS,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  UPDATE_ACCOUNTS_LIST,
  SET_SELECTED_ACCOUNT_VIEW,
  GET_SELECTED_VIEW_ACCOUNTS_FAILURE,
  GET_SELECTED_VIEW_ACCOUNTS_SUCCESS,
  GET_SELECTED_VIEW_ACCOUNTS_REQUEST,
);
