import format from '@zaveit/format';
import { formatTitle } from './formatHelper';

export const formatConsumptionValue = (value, name, consumptions) => {
  if (value) {
    const consumptionType = consumptions?.find((item) => item?.name === name);

    if (consumptionType?.data_type === 'numeric') {
      return value;
    }
    if (consumptionType?.data_type === 'byte') {
      return format.byte(value).short;
    }
    if (consumptionType) {
      return `${value} ${consumptionType?.data_type}`;
    }
    return value;
  }
  return null;
};

export const formatBytes = (values = []) => {
  let result = values;
  let unit = 'B';

  const maxValue = Math.max(...values);
  const marker = 1024;
  const isGB = maxValue / marker ** 3 > 1;
  const isMB = maxValue / marker ** 2 > 1;
  const isKB = maxValue / marker > 1;

  if (isKB) {
    result = values.map((item) => Math.round(item / marker));
    unit = 'KB';
  }
  if (isMB) {
    result = values.map((item) => Math.round(item / marker ** 2));
    unit = 'MB';
  }
  if (isGB) {
    result = values.map((item) => Math.round(item / marker ** 3));
    unit = 'GB';
  }

  return { result, unit };
};

export const formatToChartData = (selectedProperties, dataConfig, timelineData) => {
  return (
    selectedProperties.map((selectedProperty) => {
      const config = dataConfig?.[selectedProperty];
      const propertyData = timelineData?.[selectedProperty];
      const isByte = config?.data_type === 'byte';
      const data = isByte ? formatBytes(propertyData).result : propertyData;
      const name = config?.label_name || formatTitle(config?.name) || formatTitle(selectedProperty);

      return {
        data,
        name: isByte ? `${name}, ${formatBytes(propertyData).unit}` : name,
        color: config?.color,
        type: 'line',
      };
    }) || []
  );
};

export const getChartTitle = ({ property, dataConfig, timelineData }) => {
  const isByte = dataConfig?.[property]?.data_type === 'byte';
  const data = timelineData?.[property];
  const title = formatTitle(dataConfig?.[property]?.label_name || property);
  const text = isByte ? `${title}, ${formatBytes(data).unit}` : title;

  return text;
};
