export const propertyTableMocks = [
  {
    _id: '1',
    property: 'Licensed users',
    consumption: 1000000000,
    items: [
      {
        _id: '11',
        product: 'Veeam',
        consumption: 9009,
      },
    ],
  },
  {
    _id: '2',
    property: 'Licensed users',
    consumption: 1000000000,
  },
  {
    _id: '3',
    property: 'Licensed users3',
    consumption: 1000000000,
  },
  {
    _id: '4',
    property: 'Licensed users',
    consumption: 1000000000,
  },
  {
    _id: '5',
    property: 'Licensed users5',
    consumption: 1000000000,
  },
  {
    _id: '6',
    property: 'Licensed users5',
    consumption: 1000000000,
  },
];

export const subscriptionTableMocks = [
  {
    _id: '1',
    subscription: 'Office 365',
    cost: 8989,
    consumption: 3435334,
    currency: { code: 'NOK' },
    items: [
      {
        _id: '11',
        product: 'Veeam',
        consumption: 988888,
        cost: 8989,
        currency: { code: 'GBP' },
      },
    ],
  },
];

export const customerTableMocks = [
  {
    _id: '1',
    customer: 'Customer 1',
    cost: 8989,
    currency: { code: 'NOK' },
    items: [
      {
        _id: '11',
        product: 'Veeam',
        cost: 8989,
        currency: { code: 'GBP' },
      },
    ],
  },
];
