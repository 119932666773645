import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@zaveit/icons';
import _ from 'lodash';

import { Button, DashboardCard, TrainingProgressBar } from '@zaveit/uikit';

import { AuthState, RootState } from 'redux/types';
import { getAdminDetailedCourses } from 'api-client/training';
import { getTickets } from 'api-client/support';

import { PreviewCardData, PreviewCardPlaceholder } from 'components/constants';
import PriorityComponent from 'components/shared/PreviewCard/PriorityComponent';
import { getTimeDifference } from 'components/shared/PreviewCard/helpers';
import useStyles from './Dashboard.styles';
import CoursesBanner from './CoursesBanner';

const limit = 4;

function ConsumerAdminDashboard() {
  const { userInfo } = useSelector((state: RootState): AuthState => state.auth);
  const [loading, setLoading] = useState(false);
  const classes = useStyles({});
  const [courses, setCourses] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [sorting, setSorting] = useState({
    field: 'updated',
    isAsc: false,
    filterValue: '',
    filter: '',
  });

  const handleGetCourses = async () => {
    try {
      const data = await getAdminDetailedCourses({
        tenant: userInfo?.tenant,
        limit,
        sort: '-updated',
      });
      setCourses(data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeSortingType = useCallback(
    async ({ field, isAsc, filter, filterValue }) => {
      try {
        setLoading(true);
        const data = await getTickets({
          tenant: userInfo?.tenant,
          limit,
          field,
          isAsc,
          filter,
          filterValue,
        });
        setSorting({ field, isAsc, filterValue, filter });
        setTickets(data);
      } catch (error) {
        return error;
      }
      setLoading(false);
    },
    [userInfo],
  );

  const hadleGetTickets = async () => {
    try {
      setLoading(true);
      const { field, isAsc, filter, filterValue } = sorting;
      const data = await getTickets({
        tenant: userInfo?.tenant,
        limit,
        field,
        isAsc,
        filter,
        filterValue,
      });
      setTickets(data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const handleCreateTicket = () => {
    window.location.replace(
      `https://${window.location.host}/v2/support/company-tickets/?createTicket=true`,
    );
  };

  const handleNavigateSupport = () => {
    window.location.replace(`https://${window.location.host}/v2/support/company-tickets/`);
  };

  const handleNavigateTraining = () => {
    window.location.replace(`https://${window.location.host}/training/admin/courses`);
  };

  const handleNavigateAddCourse = () => {
    window.location.replace(`https://${window.location.host}/training/add-course`);
  };

  useEffect(() => {
    handleGetCourses();
    hadleGetTickets();
  }, []);

  return (
    <div className={classes.rightSide}>
      <CoursesBanner
        title="Enhance the Cyber Awareness in your company"
        subtitle="New to the platform? Dive into our guide on how to Get Started as a new Managed Service
        Provider (MSP)"
      />
      <div className={classes.consumerAdminCardsContainer}>
        <DashboardCard
          isLoading={loading}
          cardTitle="Training courses"
          emptyViewConfig={!courses?.length ? PreviewCardPlaceholder.courses : null}
          footerContent={
            courses?.length ? (
              <div className={classes.supportCardFooter}>
                <Button
                  onClick={handleNavigateAddCourse}
                  styleClass="transparentGreenTextSmall"
                  width="fit-content"
                >
                  <Icon iconName="add" className={classes.btnIcon} /> Explore new courses
                </Button>
                <Button onClick={handleNavigateTraining} styleClass="textGrey">
                  <span> View all</span>
                  <Icon iconName="chevron_right" className={classes.btnIcon} />
                </Button>
              </div>
            ) : null
          }
        >
          <div className={classes.tableRow}>
            <div className={classes.tableHeaderLeft}>
              <div>Name</div>
            </div>
            <div className={classes.tableHeaderRight}>
              <div className={classes.headerCellRight}>Course completion</div>
            </div>
          </div>
          {courses?.map((item) => {
            const completedCourseUsers = item?.users?.filter(
              (user) => user?.progress?.completed === user?.progress?.total,
            );
            return (
              <div className={classes.tableRow}>
                <div className={classes.tableCell}>{item?.title}</div>
                <div className={classes.tableCell}>
                  <TrainingProgressBar
                    value={completedCourseUsers?.length}
                    max={item?.users_count}
                    // @ts-ignore
                    variant="progress"
                  />
                </div>
              </div>
            );
          })}
        </DashboardCard>
        <DashboardCard
          cardTitle="Support tickets"
          sortingOptions={PreviewCardData.support.sortingTypes}
          handleChangeSortingType={handleChangeSortingType}
          showSorting={Boolean(tickets?.length)}
          isLoading={loading}
          emptyViewConfig={!tickets?.length ? PreviewCardPlaceholder.supportAdmin : null}
          footerContent={
            tickets?.length ? (
              <div className={classes.supportCardFooter}>
                <Button
                  onClick={handleCreateTicket}
                  styleClass="transparentGreenTextSmall"
                  width="fit-content"
                >
                  <Icon iconName="add" className={classes.btnIcon} /> New ticket
                </Button>
                <Button onClick={handleNavigateSupport} styleClass="textGrey">
                  <span>Go to support</span>
                </Button>
              </div>
            ) : null
          }
        >
          <div className={classes.rowsContainer}>
            {tickets?.map((item) => (
              <div className={classes.cardRow}>
                <PriorityComponent priority={item?.priority} />
                <div>
                  <div className={classes.courseTitle}>{item?.title}</div>
                  <div className={classes.courseSubTitle}>{item?.company?.name}</div>
                </div>
                <div className={classes.timeField}>
                  <Icon iconName="schedule" className={classes.timeIcon} />
                  <span>{getTimeDifference(item?.updated)}</span>
                </div>
              </div>
            ))}
          </div>
        </DashboardCard>
      </div>
    </div>
  );
}

export default ConsumerAdminDashboard;
