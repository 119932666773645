import { handleActions } from 'redux-actions';
import { getData, saveData } from 'helpers/localStorageHelper';
import {
  SET_SESSION,
  SET_IS_LOGIN_FORM_OPEN,
  GET_USER_INFO_SUCCESS,
  GET_CURRENT_TENANT_ACCOUNT_SUCCESS,
} from './auth.actions';

// ------------------------------------
// Reducer
// ------------------------------------

interface AuthState {
  session: boolean;
  loginOpen: boolean;
  userInfo: any;
  companyName: string;
}

const initialState = {
  session: getData('session') || false,
  loginOpen: false,
  userInfo: null,
  companyName: '',
} as AuthState;

export default handleActions(
  {
    [SET_SESSION]: (state, { payload: session }) => {
      saveData('session', session);
      return {
        ...state,
        session,
      };
    },
    [GET_USER_INFO_SUCCESS]: (state, { payload: userInfo }) => {
      return {
        ...state,
        userInfo,
      };
    },
    [GET_CURRENT_TENANT_ACCOUNT_SUCCESS]: (state, { payload: companyInfo }) => {
      return {
        ...state,
        companyName: companyInfo[0].name,
      };
    },
    [SET_IS_LOGIN_FORM_OPEN]: (state, { payload: open }) => {
      return {
        ...state,
        loginOpen: open,
      };
    },
  },
  initialState,
);
