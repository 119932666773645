import { call, put, takeEvery } from 'redux-saga/effects';
import { getAccounts } from 'api-client/account';
import {
  GET_ACCOUNTS_REQUEST,
  getAccountsSuccess,
  getAccountsFailure,
  GET_SELECTED_VIEW_ACCOUNTS_REQUEST,
  getSelectedViewAccountsFailure,
  getSelectedViewAccountsSuccess,
} from './account.actions';

// ------------------------------------
// Effects
// ------------------------------------

function* loadAccounts(userTenant) {
  try {
    const { data } = yield call(getAccounts, { tenant: userTenant.payload });
    yield put(getAccountsSuccess(data?.data));
  } catch (error) {
    yield put(getAccountsFailure());
  }
}

function* loadSelectedViewAccounts(userTenant) {
  try {
    const { data } = yield call(getAccounts, { tenant: userTenant.payload });
    yield put(getSelectedViewAccountsSuccess(data?.data));
  } catch (error) {
    yield put(getSelectedViewAccountsFailure());
  }
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* accountApi() {
  yield takeEvery(GET_ACCOUNTS_REQUEST, loadAccounts);
  yield takeEvery(GET_SELECTED_VIEW_ACCOUNTS_REQUEST, loadSelectedViewAccounts);
}

export default accountApi;
