import { useCallback } from 'react';
import queryString from 'query-string';

const useRedirectToSupport = () => {
  const redirectToSupport = useCallback((finding) => {
    const supportFormValues = {
      createTicket: true,
      title: finding.title,
      priority: 'moderate',
    };

    const sitename = window.location.host;
    const path = `https://${sitename}/v2/support/company-tickets`;
    const additionalParams = 'service&category&issue';
    window.location.replace(
      `${path}?${queryString.stringify(supportFormValues)}&${additionalParams}`,
    );
  }, []);

  return { redirectToSupport };
};

export default useRedirectToSupport;
