import { makeStyles } from '@material-ui/core';

import Colors from 'styles/Color.styles';
import { Shadows } from 'styles/constants';
import 'styles/fonts.scss';

type TButtonStyles = {
  width?: string;
  height?: string;
  marginTop?: string;
  marginRight?: string;
};

const useStyles = makeStyles(() => ({
  rootBtn: {
    '&.MuiButtonBase-root': {
      padding: 0,
      borderRadius: '8px',
      fontFamily: 'Lato-Black, sans-serif',
      fontSize: '14px',
      textTransform: 'none',
      color: Colors.white,
      boxSizing: 'border-box',
    },
    marginTop: ({ marginTop }: TButtonStyles) => marginTop || '0px',
    marginRight: ({ marginRight }: TButtonStyles) => marginRight,
  },
  activeGreen: {
    '&.MuiButtonBase-root': {
      width: ({ width }: TButtonStyles) => width || '90px',
      height: ({ height }: TButtonStyles) => height || '36px',
      padding: '0px 5px',
      backgroundColor: Colors.mainGreen,
      boxShadow: Shadows.buttonShadow,
      color: `${Colors.white} !important`,
    },
    '&:hover': {
      backgroundColor: Colors.hoverDarkGreen,
      boxShadow: 'none',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
  activeGreenBordered: {
    '&.MuiButtonBase-root': {
      width: ({ width }: TButtonStyles) => width || '90px',
      height: ({ height }: TButtonStyles) => height || '36px',
      padding: '0px 5px',
      border: `solid 1px ${Colors.mainGreen}`,
      color: `${Colors.mainGreen} !important`,
      backgroundColor: Colors.white,
    },
    '&:hover': {
      borderColor: Colors.hoverDarkGreen,
      color: Colors.hoverDarkGreen,
      boxShadow: 'none',
    },
    '&:disabled': {
      boxShadow: 'none',
      borderColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
  activeRedBordered: {
    '&.MuiButtonBase-root': {
      width: ({ width }: TButtonStyles) => width || '90px',
      height: ({ height }: TButtonStyles) => height || '36px',
      padding: '0px 5px',
      border: `solid 1px ${Colors.error}`,
      color: Colors.error,
      backgroundColor: Colors.white,
    },
    '&:hover': {
      borderColor: Colors.darkRed,
      color: Colors.darkRed,
      boxShadow: 'none',
    },
    '&:disabled': {
      boxShadow: 'none',
      borderColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
  tableBtn: {
    '&.MuiButtonBase-root': {
      width: ({ width }: TButtonStyles) => width || '90px',
      height: ({ height }: TButtonStyles) => height || '36px',
      padding: '0px 5px',
      backgroundColor: Colors.mainGreen,
      boxShadow: Shadows.buttonShadow,
      fontSize: '13px',
      marginTop: ({ marginTop }: TButtonStyles) => marginTop || '0px',
      marginRight: ({ marginRight }: TButtonStyles) => marginRight,
    },
    '&:hover': {
      backgroundColor: Colors.hoverDarkGreen,
      boxShadow: 'none',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
  activeGreenRound: {
    '&.MuiButtonBase-root': {
      width: ({ width }: TButtonStyles) => width || '110px',
      height: '48px',
      borderRadius: '50px',
      backgroundColor: Colors.mainGreen,
      boxShadow: Shadows.buttonShadow,
    },
    '&:hover': {
      backgroundColor: Colors.hoverDarkGreen,
      boxShadow: 'none',
    },
    '&:disabled': {
      boxShadow: 'none',
      backgroundColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
    '@media (max-width: 500px)': {
      width: () => '100%',
    },
  },
  tableButtonGrey: {
    '&.MuiButtonBase-root': {
      width: ({ width }: TButtonStyles) => width || '100%',
      height: ({ height }: TButtonStyles) => height || '36px',
      marginTop: ({ marginTop }: TButtonStyles) => marginTop || '0px',
      marginRight: ({ marginRight }: TButtonStyles) => marginRight,
      padding: '0px 5px',
      backgroundColor: Colors.greyDE,
      color: Colors.backgroundGrey,
      boxShadow: `0px 8px 16px ${Colors.shadowGrey}`,
      fontSize: '13px',
    },
    '&:hover': {
      backgroundColor: Colors.grey5,
      boxShadow: 'none',
    },
  },
  transparentButton: {
    '&.MuiButtonBase-root': {
      width: ({ width }: TButtonStyles) => width || '100%',
      minHeight: ({ height }: TButtonStyles) => height || '30px',
      padding: '4px 10px',
      backgroundColor: Colors.white,
      color: Colors.backgroundGrey,
      fontFamily: 'Lato-Bold, sans-serif',
      fontSize: '13px',
      letterSpacing: 0,
      border: `1px solid ${Colors.disabledGrey}`,
      marginTop: ({ marginTop }: TButtonStyles) => marginTop || '0px',
    },

    '&:hover': {
      backgroundColor: Colors.disabledGrey,
    },
    '&:disabled': {
      backgroundColor: Colors.disabledGrey,
      color: Colors.disabledText,
    },
  },
  textGreen: {
    '&.MuiButtonBase-root': {
      padding: '4px 4px 4px 2px',
      height: '30px',
      fontFamily: 'Lato-Bold, sans-serif',
      fontSize: '13px',
      lineHeight: '22px',
      color: Colors.mainGreen,
    },
    '& svg': {
      marginRight: '6px',
    },
  },
  textGrey: {
    '&.MuiButtonBase-root': {
      padding: '4px 7px 4px 4px',
      height: '30px',
      fontFamily: 'Lato-Bold, sans-serif',
      fontSize: '13px',
      lineHeight: '22px',
      color: Colors.backgroundGrey,
    },
    '&:disabled': {
      color: Colors.disabledText,
    },
  },
  transparentGreyBorderBtn: {
    '&.MuiButtonBase-root': {
      width: (props) => props.width || '75px',
      height: (props) => props.height || '36px',
      backgroundColor: Colors.white,
      color: Colors.backgroundGrey,
      fontFamily: 'lato-bold, sans-serf',
      border: 'solid 1px rgba(145, 158, 171, 0.32)',
      fontSize: '13px',
      marginTop: (props) => props.marginTop || '0px',
      '&:hover': {
        backgroundColor: Colors.lightBackgroundGrey,
        boxShadow: 'none',
      },
    },
    '&:disabled': {
      backgroundColor: Colors.disabledGrey,
      color: Colors.disabledText,
      border: `solid 1px ${Colors.disabledGrey}`,
    },
  },
}));

export default useStyles;
