import axios from 'axios';
import Config from 'config/index';

const instance = axios.create({ baseURL: Config.baseUserUrl, withCredentials: true });

export const getSiteSettings = ({ sitename }) => {
  return instance.get(`${Config.baseUserUrl}/v1/tenant/site/${sitename}`);
};

export const getDeliveryByStatus = async ({ status }: { status: string }) => {
  try {
    const { data } = await instance.get('/v1/order/delivery', {
      params: {
        'filter[status]': status,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
