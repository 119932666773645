// @ts-nocheck
import ReactPlayer from 'react-player';
import useStyles from './VideoPlayer.styles';

type Props = {
  url: string;
};

// url like "https://media.w3.org/2010/05/sintel/trailer_hd.mp4"

const VideoPlayer = ({ url }: Props) => {
  const classes = useStyles();

  return (
    // <div className={classes.videoContainer}>
    <ReactPlayer
      className={classes.container}
      stopOnUnmount
      url={url}
      width="700px"
      height="390px"
      controls
      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
    />
    // </div>
  );
};

export default VideoPlayer;
