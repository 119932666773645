export const Features = {
  risc: 'RISC',
  accountManagement: 'AccountManagement',
  platform: 'Platform',
  vulnerability: 'Vulnerability',
  consumption: 'Consumption',
  userManagement: 'UserManagement',
  userManagementCreate: 'UserManagement/Create',
  health: 'Health',
  support: 'Platform',
};
