import { CroppableText, DataGridColumns } from '@zaveit/uikit';
import { formatConsumptionValue } from 'helpers/consumption';

export const usePropertyTableConfig = (
  consumptions,
): { headCellsProperty: DataGridColumns; headCellsProduct: DataGridColumns } => {
  const headCellsProperty: DataGridColumns = [
    {
      field: 'name',
      headerName: 'Property',
      minWidth: 200,
      flex: 3,
      renderCell: ({ value }) => {
        const readableValue = value.replace('_', ' ');
        const property = consumptions?.find((item) => item?.name === value);
        return value ? (
          <CroppableText value={property?.label_name || readableValue} />
        ) : (
          <>&mdash;</>
        );
      },
    },
    {
      field: 'value',
      headerName: 'Consumption',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
      renderCell: ({ row }) => {
        const value = formatConsumptionValue(row?.value, row?.name, consumptions);
        return value || <>&mdash;</>;
      },
    },
  ];

  const headCellsProduct: DataGridColumns = [
    {
      field: 'name',
      headerName: 'Product',
      minWidth: 200,
      flex: 3,
      renderCell: ({ value }) => (value ? <CroppableText value={value} /> : <>&mdash;</>),
    },
    {
      field: 'value',
      headerName: 'Consumption',
      type: 'number',
      minWidth: 150,
      flex: 0.5,
      renderCell: ({ row }) => {
        const value = formatConsumptionValue(row?.value, row?.name, consumptions);
        return value || <>&mdash;</>;
      },
    },
  ];

  return { headCellsProperty, headCellsProduct };
};
