import { call, put, takeEvery } from 'redux-saga/effects';
import { getFindingsTable } from 'api-client/risc';
import { setSession } from 'redux/auth/auth.actions';
import {
  GET_FINDINGS_DATA_REQUEST,
  getFindingsDataFailure,
  getFindingsDataSuccess,
} from './findings.actions';

// ------------------------------------
// Effects
// ------------------------------------

function* loadFindingsData({ payload }) {
  try {
    const { data } = yield call(getFindingsTable, payload.tenant, payload.filter);
    yield put(getFindingsDataSuccess(data));
    yield put(setSession(true));
  } catch (error) {
    if (error.response?.status === 401 || error.response?.statusText === 'Unauthorized') {
      yield put(setSession(false));
    }
    yield put(getFindingsDataFailure());
  }
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* findingsApi() {
  // @ts-expect-error
  yield takeEvery(GET_FINDINGS_DATA_REQUEST, loadFindingsData);
}

export default findingsApi;
