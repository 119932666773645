import { makeStyles } from '@material-ui/core';
import { Colors } from '@zaveit/uikit';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    minHeight: '276px',
    maxHeight: '276px',
    borderRadius: '16px',
    backgroundColor: Colors.white,
    boxShadow:
      '0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0px',
  },
  topSection: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '58px',
    maxHeight: '58px',
    padding: '24px 24px 0 24px',
  },
  titleTrendRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  trendSection: {
    display: 'flex',
    gap: '4',
  },
  chartSection: {
    height: '100%',
    marginLeft: '-10px',
  },
  bottomSection: {
    height: '90px',
    padding: '0px 24px 0px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
  },
  h4: {
    fontFamily: 'Poppins-semibold, sans-serif',
    fontSize: '24px',
    lineHeight: '36px',
    color: Colors.backgroundGrey,
  },
  subtitle2: {
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '22px',
    color: Colors.backgroundGrey,
  },
  caption: {
    fontFamily: 'Lato',
    fontSize: '12px',
    lineHeight: '18px',
    color: Colors.backgroundGrey,
  },
  positiveTrend: {
    fontFamily: 'Lato',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#00C9A7',
  },
  negativeTrend: {
    fontFamily: 'Lato',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FF4842',
  },
  chartSkeleton: {
    padding: '0px 24px 0px 24px',
  },
}));

export default useStyles;
