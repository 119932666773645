import { makeStyles, Theme, Box, styled } from '@material-ui/core';

import Colors from 'styles/Color.styles';
import { Shadows } from 'styles/constants';

type TProps = {
  itemHeight?: string;
  placeholderHeight?: string;
};

export const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderRadius: '16px',
    boxShadow: Shadows.cardShadow,
  },
  header: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Poppins-Semibold, sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    color: Colors.backgroundGrey,
  },
  footer: {
    padding: '16px 24px',
    height: '30px',
    display: 'flex',
    boxShadow: `inset 0px 1px 0px ${Colors.shadowGrey}`,
  },
  loader: {
    height: ({ placeholderHeight }: TProps) => placeholderHeight,
    justifyContent: 'center',
  },
  linkBtnContainer: {
    position: 'absolute',
    right: '24px',
  },
  linkBtnIcon: {
    marginLeft: '11px',
    fontSize: '12px',
  },
  itemsContainer: { flex: 1 },
  childrenContainer: { padding: '0 8px', flex: 1 },
  itemContainer: {
    height: ({ itemHeight }: TProps) => itemHeight,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down(970)]: {
      paddingLeft: () => '16px',
    },
  },
  itemIconContainer: {
    marginRight: '12px',
    width: '44px',
    minWidth: '44px',
    height: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',

    '& img': {
      width: '24px',
    },
  },
  itemIconContainerPriority: {
    marginRight: '12px',
    width: '44px',
    minWidth: '44px',
    height: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',

    '& img': {
      width: '14px',
      height: '16px',
    },
  },
  itemIconPlaceholder: {
    color: Colors.placeholderColor,
  },
  titleContainer: {
    width: '100%',
    '&, & .MuiTypography-body1': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  serviceContainer: { width: '100%', display: 'flex' },
  timeContainer: { minWidth: '71px', display: 'flex', justifyContent: 'center' },
  riscContainer: { paddingLeft: '16px', minWidth: '57px' },
  avatar: {
    marginRight: '16px',
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
  },
  itemTitle: {
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: Colors.backgroundGrey,
  },
  itemText: {
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: Colors.backgroundGrey,
  },
  itemConsumer: {
    fontFamily: 'Lato-Medium, sans-serif',
    fontSize: '12px',
    lineHeight: '18px',
    color: Colors.subtitleGrey,
  },
  itemTime: {
    fontFamily: 'Lato-Medium, sans-serif',
    fontSize: '12px',
    lineHeight: '18px',
    color: Colors.placeholderColor,
  },
  clockIcon: { marginRight: '4px', fontSize: '18px', color: Colors.placeholderColor },
  serviceIcon: {
    background: Colors.lightBackgroundGrey,

    '& span': {
      fontSize: '22px',
    },
  },
  security: {
    color: Colors.mainGreen,
  },
  lowestPriority: { backgroundColor: Colors.lightBlueTransparent },
  lowPriority: { backgroundColor: Colors.blueTransparent },
  mediumPriority: { backgroundColor: Colors.warningTransparent },
  moderatePriority: { backgroundColor: Colors.warningTransparent },
  highPriority: { backgroundColor: Colors.orangeTransparent },
  highestPriority: { backgroundColor: Colors.transparentRed },
  placeholderContainer: {
    margin: 'auto',
    maxWidth: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '280px',
    height: ({ placeholderHeight }: TProps) => placeholderHeight,

    [theme.breakpoints.down(970)]: {
      padding: () => '16px',
    },
  },
  placeholderIconContainer: {
    marginBottom: '24px',
    width: '56px',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '9px',
    backgroundColor: Colors.iconBackgroundGrey,
  },
  placeholderIcon: {
    fontSize: '30px',
    color: Colors.placeholderColor,
  },
  placeholderTitle: {
    fontFamily: 'Poppins-Semibold, sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    color: Colors.subtitleGrey,
  },
  placeholderDescription: {
    marginTop: '8px',
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: Colors.subtitleGrey,
  },
  itemHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.lightBackgroundGrey,
    },
  },
  lastItem: { marginRight: '24px' },
}));

export default useStyles;
