import dayjs from 'dayjs';

import { ChartColors } from 'components/ConsumptionPage/Timeline/constants';
import { getChartTitle } from 'helpers/consumption';

import Colors from 'styles/Color.styles';
import { excludeExistingColors } from 'components/helpers/consumption';

export const useChartOptions = ({
  timeLineDates,
  selectedProperties,
  dataConfig,
  timelineData,
  isLoading,
}) => ({
  chart: {
    height: 350,
    offsetY: 32,
    toolbar: { show: false },
    zoom: { enabled: false },
    animations: {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 1000,
      },
    },
    foreColor: Colors.borderGrey,
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: 20,
  },
  xaxis: {
    categories: timeLineDates,
    axisBorder: { show: false },
    axisTicks: { show: false },
    type: 'datetime',
    tooltip: {
      formatter(value) {
        return dayjs(value).format('DD MMM');
      },
    },
  },
  yaxis: selectedProperties.map((property, index) => {
    return {
      title: {
        text: getChartTitle({ property, dataConfig, timelineData }),
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
      },
      opposite: index % 2 && selectedProperties.length > 3,
    };
  }),
  states: {
    hover: {
      filter: {
        type: 'lighten',
        value: 0.04,
      },
    },
    active: {
      filter: {
        type: 'darken',
        value: 0.88,
      },
    },
  },
  colors: excludeExistingColors({
    allColors: ChartColors,
    config: dataConfig,
    selectedProps: selectedProperties,
  }),
  stroke: {
    width: 3,
    curve: 'smooth',
    lineCap: 'round',
  },
  grid: {
    strokeDashArray: 4,
  },
  dataLabels: { enabled: false },
  markers: {
    size: 0,
  },
  tooltip: {
    x: {
      show: false,
    },
    y: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  noData: {
    text: isLoading ? 'Loading...' : "No timeline data. Make sure you've selected a property.",
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: -15,
    style: {
      color: Colors.backgroundGrey,
      fontSize: '14px',
      fontFamily: 'Lato-Normal, sans-serif',
    },
  },
});
