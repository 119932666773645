import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  innerTableContainer: {
    padding: '0 24px',
    marginTop: '-48px',
  },
}));

export default useStyles;
