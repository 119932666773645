// @ts-nocheck
import classnames from 'classnames';
import { Box } from '@material-ui/core';

import { PreviewItemIcons } from 'components/constants';
import useStyles from './PreviewCard.styles';

type TPreviewItem = {
  priority: string;
};

const PriorityComponent: React.FC<TPreviewItem> = ({ priority }) => {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.itemIconContainerPriority, classes[`${priority}Priority`])}>
      <img src={PreviewItemIcons.priority[priority]} alt="" />
    </Box>
  );
};

export default PriorityComponent;
