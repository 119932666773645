import { Container, Grid } from '@material-ui/core';
import { Feature } from '@zaveit/feature';
import { TFinding } from '@zaveit/uikit/dist/cjs/types/components/FindingDetailsModal/types';
import Findings from 'components/Findings/Findings';
import {
  getDashboardData,
  updateProviderFindingsData,
  getTargetsData,
  updateConsumerFindingsData,
  getProviderTileData,
} from 'components/helpers';
import { useSelector } from 'react-redux';
import { TCustomer, TTarget } from 'components/types';
import { Features } from 'constants/features';
import { useEffect, useState } from 'react';
import { TileData, DailyStatusItem } from 'types/home';
import Skeleton from '@mui/material/Skeleton';
import DataTile from './DataTiles/DataTile';
import DataTileRISC from './DataTiles/DataTileRISC';
import useStyles from './Home.styles';
import TaskItem from './Task/TaskItem';

export default function ProviderAdminView() {
  // @ts-expect-error
  const { userInfo } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isTileDataLoading, setIsTileDataLoading] = useState(true);

  // Findings table
  const [findings, setFindings] = useState<TFinding[]>([]);
  const [customers, setCustomers] = useState<TCustomer[]>([]);
  const [targets, setTargets] = useState<TTarget[]>();
  // Data tiles
  const [customerHistory, setCustomerHistory] = useState<TileData | null>(null);
  const [salesHistory, setSalesHistory] = useState<TileData | null>(null);
  const [ticketHistory, setTicketHistory] = useState<TileData | null>(null);
  const [riscHistory, setRiscHistory] = useState<TileData | null>(null);
  const [criticalFindings, setCriticalFindings] = useState<object[] | null>(null);
  // Daily status
  const [dailyStatus, setDailyStatus] = useState<DailyStatusItem[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const {
          riscHistory: riscHistory,
          findings: findingsData,
          customers: customersData,
          customerHistory: customerHistory,
          backup: backupData,
          vsaas: vsaasData,
          salesHistory: salesHistory,
          ticketHistory: ticketHistory,
          dailyStatus: dailyStatus,
          criticalFindings: criticalFindings,
        } = await getDashboardData(userInfo?.tenant, userInfo?.msp);

        if (userInfo?.msp) {
          setFindings(updateProviderFindingsData(findingsData?.data, customersData));
        } else {
          const targetsData = getTargetsData(findingsData?.data, backupData, vsaasData);
          setFindings(updateConsumerFindingsData(findingsData?.data, targetsData));
          setTargets(targetsData);
        }

        setSalesHistory(salesHistory);
        setCustomers(customersData);
        setCustomerHistory(customerHistory);
        setTicketHistory(ticketHistory);
        setDailyStatus(dailyStatus);
        setRiscHistory(riscHistory);
        setCriticalFindings(criticalFindings);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (userInfo?.tenant) {
      getData();
    }
  }, [userInfo?.tenant, userInfo?.msp]);

  useEffect(() => {
    const getData = async () => {
      try {
        const {
          customerHistory: customerHistory,
          salesHistory: salesHistory,
          ticketHistory: ticketHistory,
        } = await getProviderTileData(userInfo?.tenant);

        setSalesHistory(salesHistory);
        setCustomerHistory(customerHistory);
        setTicketHistory(ticketHistory);
      } catch (error) {
        console.log(error);
      }
      setIsTileDataLoading(false);
    };

    if (userInfo?.tenant) {
      getData();
    }
  }, [userInfo?.tenant, userInfo?.msp]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DataTile
            isLoading={isTileDataLoading}
            buttonName="View orders"
            title="Sales today"
            message="confirmed orders"
            tooltipLabel="Total sales"
            data={salesHistory}
            redirectPath="/account-manager/orders"
            isCurrency={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DataTile
            isLoading={isTileDataLoading}
            buttonName="View customers"
            title="Customers"
            message="new customers"
            tooltipLabel="Customers"
            data={customerHistory}
            redirectPath="/account-manager/accounts"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DataTile
            isLoading={isTileDataLoading}
            buttonName="View tickets"
            title="Open tickets"
            message="new tickets"
            tooltipLabel="Open tickets"
            data={ticketHistory}
            redirectPath="/v2/support/customer-tickets/"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DataTileRISC
            isLoading={isLoading}
            title="RISC"
            findingsData={criticalFindings}
            message="critical findings"
            tooltipLabel="RISC"
            redirectPath="/findings"
            findings={findings}
            data={riscHistory}
          />
        </Grid>
      </Grid>

      {isLoading ? (
        <div>
          <div className={classes.skeletonContainer}>
            <Skeleton width="100%" height={50} />
          </div>
          <div className={classes.skeletonContainer}>
            <Skeleton variant="rounded" width="100%" height={250} />
          </div>
        </div>
      ) : (
        <div className={classes.middleSection}>
          <div className={classes.taskContainer}>
            {dailyStatus.map((item, index) => {
              if (item.count === 0) return null;

              const isLastItem = index === dailyStatus.length - 1;
              const isFirstItem = index === 0;

              return (
                <TaskItem
                  key={item.title}
                  data={item}
                  lastIndex={isLastItem ? index : isFirstItem ? 0 : undefined}
                />
              );
            })}
          </div>
          <Feature feature={Features.risc}>
            <Findings
              tenant={userInfo?.tenant}
              values={findings}
              customers={customers}
              targets={targets}
              handleChange={setFindings}
              isMsp={userInfo?.msp}
              isLoading={isLoading}
            />
          </Feature>
        </div>
      )}
    </Container>
  );
}
