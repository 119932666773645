import axios from 'axios';
import Config from 'config/index';

export const loginUser = (username) => {
  const response = axios.post(`${Config.baseAuthUrl}/v1/auth`, { username });
  return response;
};

export const logOut = () => {
  const response = axios.post(`${Config.baseAuthUrl}/v1/auth/logout`);
  return response;
};

type VerifyData = {
  username: string;
  mail: number;
  sms: number;
};

export const verifyUser = (data: VerifyData, register) => {
  axios.defaults.withCredentials = true;
  const response = axios.post(`${Config.baseAuthUrl}/v1/auth/verify?register=${register}`,
    {
      ...data,
    });
  return response;
};

export const getAuthInfo = () => {
  axios.defaults.withCredentials = true;
  const response = axios.get(`${Config.baseAuthUrl}/v1/auth/info`);
  return response;
};
