/* eslint-disable newline-per-chained-call */
import { TimelinePeriodsEnum } from 'components/ConsumptionPage/constants';
import dayjs from 'dayjs';
import { getData } from 'helpers/localStorageHelper';

export const getStartDateByPeriod = (period: string) => {
  let startDate;
  let endDate = dayjs().utc().toISOString();

  switch (period) {
    case TimelinePeriodsEnum.CURRENT_MONTH: {
      startDate = dayjs().utc().startOf('month').toISOString();
      break;
    }
    case TimelinePeriodsEnum.LAST_MONTH: {
      startDate = dayjs().utc().subtract(1, 'month').startOf('month').toISOString();
      endDate = dayjs().utc().subtract(1, 'month').endOf('month').toISOString();
      break;
    }
    case TimelinePeriodsEnum.THREE_MONTHS: {
      startDate = dayjs().utc().subtract(3, 'month').toISOString();
      break;
    }
    case TimelinePeriodsEnum.SIX_MONTHS: {
      startDate = dayjs().utc().subtract(6, 'month').toISOString();
      break;
    }
    case TimelinePeriodsEnum.YEAR_TO_DATE: {
      startDate = dayjs().utc().startOf('year').toISOString();
      break;
    }
    case TimelinePeriodsEnum.ONE_YEAR: {
      startDate = dayjs().utc().subtract(1, 'year').toISOString();
      break;
    }
    default:
      break;
  }

  return { startDate, endDate };
};

export const getStoredConfig = (tableName: string) => getData(`datagrid/${tableName}`);

export const excludeExistingColors = ({ allColors, config = {}, selectedProps }) => {
  const existingColors = Object.entries(config)
    .filter(([key, value]) => (value as any).color && selectedProps?.includes(key))
    .map(([_, value]) => (value as any).color);

  return allColors.filter((color) => !existingColors.includes(color)) || [];
};
