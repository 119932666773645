const isDevelopment = window.location.hostname === 'localhost';
const environment = isDevelopment ? 'development' : 'prod';

let domain = window.location.host.split('.').slice(-2).join('.');
let { protocol } = window.location;

if (process.env.REACT_APP_IS_RED_ENV) {
  domain = 'zit.red';
  protocol = 'https:';
}

const configs = {
  development: {
    baseAuthUrl: 'http://localhost:3334',
    baseAccountUrl: 'http://localhost:3336',
    baseVsaasUrl: 'http://localhost:3340',
    baseBackupUrl: 'http://localhost:3350',
    baseUserUrl: 'http://localhost:3333',
    baseFileUrl: 'http://localhost:3370',
    baseConsumptionUrl: 'http://localhost:3367',
    baseRiscUrl: 'http://localhost:3368',
    baseHealthUrl: 'http://localhost:3369',
    basePublicUrl: 'http://localhost:3390',
    baseSupportUrl: 'http://localhost:3352',
    baseOrderUrl: 'http://localhost:3337',
    baseTrainingUrl: 'http://localhost:3500',
  },
  prod: {
    baseAuthUrl: `${protocol}//api.${domain}`,
    baseAccountUrl: `${protocol}//api.${domain}`,
    baseVsaasUrl: `${protocol}//api.${domain}`,
    baseBackupUrl: `${protocol}//api.${domain}`,
    baseUserUrl: `${protocol}//api.${domain}`,
    baseFileUrl: `${protocol}//api.${domain}`,
    baseConsumptionUrl: `${protocol}//api.${domain}`,
    baseRiscUrl: `${protocol}//api.${domain}`,
    baseHealthUrl: `${protocol}//api.${domain}`,
    basePublicUrl: `${protocol}//api.${domain}`,
    baseSupportUrl: `${protocol}//api.${domain}`,
    baseOrderUrl: `${protocol}//api.${domain}`,
    baseTrainingUrl: `${protocol}//api.${domain}`,
  },
};

const config = process.env.REACT_APP_IS_RED_ENV
  ? configs.prod
  : configs[environment] || configs.development;

export default config;
