import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  titleContainer: { margin: '40px auto 40px 0' },
  tablesHeader: {
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
