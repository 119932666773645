import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; // Full viewport height
    background-color: #ffffff; // Optional: set background color here if not set elsewhere
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 230px;
  padding: 20px; // Add padding for better visual spacing
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Optional: adds a subtle shadow for depth
`;

export const Title = styled.h1`
  font-size: 32px;
  color: #00c9a7;
  text-align: center; // Ensure the title is centered within the container
  margin-bottom: 20px; // Spacing below the title
`;

export const Form = styled.form`
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  border-bottom: 2px solid #00c9a7;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
`;

export const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #00c9a7;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #009688;
  }
`;

export const Message = styled.div`
  color: #ff3333;
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  width: 100%;
  overflow-wrap: break-word;
`;

export const SuccessMessage = styled(Message)`
  color: #00c9a7;
`;

export const StatusCode = styled.div`
  font-size: 24px;
  color: #808080; // Set to gray color
  text-align: center;
  margin-top: 10px;
`;
