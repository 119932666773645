// @ts-nocheck
import { lighten, makeStyles } from '@material-ui/core';

import Colors from 'styles/Color.styles';
import { Shadows } from 'styles/constants';
import bannerBc from 'static/svg/bannerBc.jpeg';
import bannerSmall from 'static/svg/bannerSmall.jpg';
import 'styles/fonts.scss';

const tab = {
  textTransform: 'none',
  fontFamily: 'Lato-Normal, sans-serif',
  padding: '0px',
  marginRight: '40px',
  minWidth: 'auto',
  fontSize: '18px',
  fontWeight: 600,
};

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '40px',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
  sectionsContainer: {
    marginBottom: '24px',
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 12px) calc(50% - 12px)',
    gap: '24px',
  },
  sectionsRight: { display: 'flex', flexDirection: 'column' },
  bottomCardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'calc(40% - 12px) calc(60% - 12px)',
    gap: '24px',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  tabRoot: {
    ...tab,
    color: Colors.subtitleGrey,
  },
  tabSelected: {
    ...tab,
    color: Colors.greyIcon,
  },
  tabsContainer: {
    marginBottom: '24px',
    paddingLeft: '15px',
  },
  indicator: {
    backgroundColor: Colors.mainGreen,
  },
  chartsContainer: {
    display: 'flex',
    '@media (max-width: 970px)': {
      flexDirection: 'column',
    },
  },
  cardTitle: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 600,
    color: Colors.backgroundGrey,
    fontFamily: 'Lato-Normal, sans-serif',
    letterSpacing: '0.5px',
  },
  cardsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '16px',
    boxShadow: Shadows.cardShadow,
    padding: '24px',
    marginBottom: '24px',
    boxSizing: 'border-box',
    position: 'relative',
    flexGrow: 1,
    maxWidth: '49%',
    '&:first-child': {
      marginRight: '24px',
    },
  },
  singleCard: {
    width: '50%',
    flexGrow: 0,
    '&:first-child': {
      marginRight: '0px',
    },
  },
  cardImg: {
    width: '143px',
  },
  cardBody: {
    display: 'flex',
  },
  cardText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: Colors.subtitleGrey,
    fontFamily: 'Lato-Normal, sans-serif',
    margin: '10px 10px 0px 0px',
  },
  bannerContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    padding: '40px',
    backgroundImage: ({ isSmall }: { isSmall?: boolean }) =>
      isSmall ? `url(${bannerSmall})` : `url(${bannerBc})`,
    backgroundPositionY: ({ isSmall }: { isSmall?: boolean }) => (isSmall ? '0px' : '-250px'),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: ({ isSmall }: { isSmall?: boolean }) => (isSmall ? '0px' : '40px'),
  },
  bannerTitle: {
    fontFamily: 'Poppins-Semibold, sans-serif',
    fontSize: '24px',
    lineHeight: '36px',
    color: Colors.white,
  },
  bannerSubTitle: {
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    color: Colors.white,
    marginTop: '8px',
    fontWeight: 400,
  },
  exploreBtnContainer: {
    backgroundColor: Colors.white,
    width: 'fit-content',
    borderRadius: '8px',
    marginTop: ({ isSmall }: { isSmall?: boolean }) => (isSmall ? 'auto' : '40px'),
    justifySelf: 'flex-end',
  },
  contentCardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '24px',
  },
  cardContainer: {
    boxShadow:
      '0px 16px 32px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px 0px rgba(145, 158, 171, 0.24)',
    borderRadius: '16px',
    height: '458px',
    minWidth: '344px',
    padding: '12px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  cardContainerEmpty: {
    justifyContent: 'center',
  },
  iconCourseContainer: {
    width: '40px',
    height: '40px',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.darkBlue,
    backgroundColor: lighten(Colors.darkBlue, 0.84),
    fontSize: '20px',
  },
  courseTitleContainer: {
    display: 'flex',
    gap: '12px',
    padding: '12px 12px 16px 12px',
  },
  courseTitle: {
    color: Colors.backgroundGrey,
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
  },
  courseSubTitle: {
    color: Colors.subtitleGrey,
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '12px',
    lineHeight: '18px',
  },
  courseDescr: {
    padding: '0px 12px 16px 12px',
    color: Colors.backgroundGrey,
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '12px',
    lineHeight: '18px',
  },
  imageContainer: {
    borderRadius: '12px',
    backgroundColor: lighten(Colors.placeholderColor, 0.84),
    height: '260px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'flex-end',
    marginTop: 'auto',
    color: Colors.placeholderColor,
    fontSize: '70px',
  },
  courseBtnContainer: {
    padding: '0 12px',
  },
  emptyContainer: {
    margin: '60px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  btnIcon: {
    fontSize: '18px',
    marginRight: '8px',
    display: 'inline-block',
  },
  btnIconRight: {
    fontSize: '18px',
    marginLeft: '8px',
    display: 'inline-block',
  },
  supportCardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ticketTitle: {
    color: Colors.backgroundGrey,
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
  },
  ticketTime: {
    color: Colors.subtitleGrey,
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '12px',
    lineHeight: '18px',
  },
  ticketsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
  },
  consumerAdminCardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
  },
  tableRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  tableHeaderRight: {
    borderRadius: '0px 8px 8px 0px',
    backgroundColor: Colors.lightBackgroundGrey,
    padding: '14px 16px 14px 0px',
    color: Colors.subtitleGrey,
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
  },
  tableHeaderLeft: {
    borderRadius: '8px 0px 0px 8px',
    backgroundColor: Colors.lightBackgroundGrey,
    padding: '14px 16px',
    color: Colors.subtitleGrey,
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
  },
  headerCellRight: {
    borderLeft: `solid 2px ${Colors.shadowGrey}`,
    paddingLeft: '16px',
    textAlign: 'right',
  },
  tableCell: {
    padding: '14px 16px',
    color: Colors.backgroundGrey,
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
  },
  cardRow: {
    display: 'grid',
    gridTemplateColumns: '44px 1fr auto',
    gap: '12px',
    justifyContent: 'space-between',
  },
  rowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  timeField: {
    fontSize: '12px',
    lineHeight: '18px',
    color: Colors.placeholderColor,
    fontFamily: 'Lato-Normal, sans-serif',
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  timeIcon: {
    fontSize: '16px',
  },
  buttonCardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
