export enum Roles {
  OWNER_ADMIN = 'owner.admin',
  PROVIDER_ADMIN = 'provider.admin',
  CONSUMER_ADMIN = 'consumer.admin',
  CONSUMER_USER = 'consumer.user',
}

export enum Currencies {
  USD = 'us',
  NOK = 'no',
  GBP = 'uk',
  EUR = 'eu',
}
