import React, { useState } from 'react';
import {
  Container,
  Title,
  Form,
  Input,
  Button,
  Message,
  SuccessMessage,
  StatusCode,
} from './CookieDispenser.style';
import { cookieGreetings, noCookieMessages } from './messages';

const CookieDispenser = () => {
  const [cookieCode, setCookieCode] = useState('');
  const [message, setMessage] = useState('');
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const sitename = process.env.REACT_APP_IS_RED_ENV ? 'app.zit.red' : window.location.host;
  const domain = sitename.split('.').slice(-2).join('.');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`https://api.${domain}/v1/auth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Session: cookieCode,
        },
        body: JSON.stringify({}),
        credentials: 'include',
      });
      setStatusCode(response.status);
      if (response.ok) {
        const data = await response.json();
        const randomGreeting = cookieGreetings[Math.floor(Math.random() * cookieGreetings.length)];
        setMessage(randomGreeting);
        setIsSuccess(true);
      } else {
        throw new Error('No treat for you');
      }
    } catch (error) {
      const randomError = noCookieMessages[Math.floor(Math.random() * noCookieMessages.length)];
      setMessage(randomError);
      setIsSuccess(false);
    }
  };

  return (
    <Container>
      <Title>Cookie Dispenser</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          id="cookieCode"
          name="cookieCode"
          placeholder="Enter Magic Words"
          value={cookieCode}
          onChange={(e) => setCookieCode(e.target.value)}
          required
        />
        <Button id="requestCookie" type="submit">
          Dispense Cookie
        </Button>
      </Form>
      {message &&
        (isSuccess ? <SuccessMessage>{message}</SuccessMessage> : <Message>{message}</Message>)}
      {statusCode && <StatusCode id="responseCode">{statusCode}</StatusCode>}
    </Container>
  );
};

export default CookieDispenser;
