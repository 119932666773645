import dayjs from 'dayjs';

export const handleGetDiffTime = (date) => {
  const today = dayjs(new Date());
  let diff;
  let prefix = 'day';

  const diffInDay = today.diff(dayjs(date), 'day');

  if (diffInDay < 1) {
    const diffInSec = today.diff(dayjs(date), 'second');
    if (diffInSec > 60) {
      const diffInMin = today.diff(dayjs(date), 'minute');
      if (diffInMin > 60) {
        const diffInHour = today.diff(dayjs(date), 'hour');
        prefix = 'hour';
        diff = diffInHour;
      } else {
        diff = diffInMin;
        prefix = 'minute';
      }
    } else {
      diff = diffInSec;
      prefix = 'second';
    }
  }

  if (diffInDay > 7) {
    diff = today.diff(dayjs(date), 'week');
    prefix = 'week';
  }

  const diffInMonth = today.diff(dayjs(date), 'month');
  if (diffInMonth >= 1) {
    diff = diffInMonth;
    prefix = 'month';
  }

  if (diffInMonth > 12) {
    diff = today.diff(dayjs(date), 'year');
    prefix = 'year';
  }
  const end = diff > 1 ? 's' : '';

  return `${diff} ${prefix}${end} ago`;
};
