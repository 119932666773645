import { makeStyles } from '@material-ui/core';

import Colors from 'styles/Color.styles';
import 'styles/fonts.scss';

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '53px',
    marginTop: '40px',
    color: Colors.backgroundGrey,
    fontFamily: 'Poppins-Semibold, sans-serif',
    fontSize: '24px',
    lineHeight: '36px',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
  },
  collapseContainer: {
    backgroundColor: Colors.collapsBackgroundGrey,
    padding: '16px 8px 16px 60px',
  },
  collapseTitle: {
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: Colors.backgroundGrey,
  },
  infoContainer: {
    display: 'flex',
    marginTop: '16px',
    marginBottom: '8px',
  },
  infoTitle: {
    color: Colors.subtitleGrey,
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Lato-Normal, sans-serif',
    marginRight: '8px',
  },
  infoDescr: {
    color: Colors.backgroundGrey,
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Lato-Normal, sans-serif',
    marginRight: '24px',
  },
  descrContainer: {
    marginBottom: '32px',
  },
  relatedFindingTitleLink: {
    color: Colors.backgroundGrey,
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Lato-Normal, sans-serif',
    textDecoration: 'underline',
  },
  relatedFindingInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '29px',
  },
  actionContainer: {
    display: 'flex',
  },
  menuIconBtn: {
    color: Colors.subtitleGrey,
    fontSize: '24px',
    marginLeft: '-25px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: Colors.darkest2Grey,
    },
  },
  deleteAction: {
    color: Colors.red,
  },
  loader: {
    color: Colors.mainGreen,
    marginRight: '24px',
  },
  loaderBig: {
    color: Colors.mainGreen,
    margin: '10px auto',
    display: 'block',
  },
  tabSelected: {
    textTransform: 'none',
    fontFamily: 'Lato-SemiBold, sans-serif',
    padding: '0px',
    marginRight: '40px',
    minWidth: 'auto',
    fontSize: '14px',
    lineHeight: '22px',
    color: Colors.greyIcon,
    opacity: 1,
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    width: '100%',
    '& .MuiTabs-root': {
      height: '48px',
    },
  },
  indicator: {
    backgroundColor: Colors.mainGreen,
  },
  tabRoot: {
    textTransform: 'none',
    fontFamily: 'Lato-SemiBold, sans-serif',
    color: Colors.subtitleGrey,
    lineHeight: '22px',
    opacity: 1,
    marginRight: '40px',
    padding: '0px',
    minWidth: 'auto',
    '&.MuiTab-labelIcon': {
      minHeight: '48px',
    },
    '& .MuiTab-wrapper': {
      padding: '0px',
      flexDirection: 'row',
      minWidth: 'auto',
      fontSize: '14px',
      '&>:first-child': {
        marginBottom: 0,
        marginRight: 10,
      },
    },
  },
  riscScoreContainer: {
    marginRight: '46px',
  },
  selectContainer: {
    width: '160px',
  },
  filtersContainer: {
    display: 'grid',
    gap: '4px 24px',
    gridTemplateColumns: 'repeat( auto-fit, minmax(160px, 1fr) )',
    justifyContent: 'space-between',
  },
  clearBtn: {
    color: Colors.error,
    fontFamily: 'Lato-Black, sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    width: 'fit-content',
    height: '36px',
    '&:hover': {
      color: Colors.darkRed,
    },
  },
  clearIcon: {
    fontSize: '18px',
    marginRight: '8px',
    marginTop: '2px',
  },
  loaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  backIcon: {
    cursor: 'pointer',
    marginRight: '30px',
    '&:hover': {
      color: Colors.placeholderColor,
    },
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: '12px',
    position: 'absolute',
    right: '0px',
    top: '16px',
  },
  spiner: {
    color: Colors.mainGreen,
  },
  hiddenHeaderCell: {
    position: 'absolute',
    width: 20,
    top: 0,
    left: 2,
    backgroundColor: '#F4F6F8',
    zIndex: 200,
    color: '#F4F6F8',
  },
}));

export default useStyles;
