import { Accordion, Button, Loader } from '@zaveit/uikit';
import { Icon } from '@zaveit/icons';
import classnames from 'classnames';

import VideoPlayer from 'components/shared/VideoPlayer';
import logoIcon from 'static/svg/logoIcon.svg';
import useStyles from './GettingStarted.styles';

const GettingStartedCard = ({ isConsumer, task, handleCompleteTask, loading, open, setOpen }) => {
  const classes = useStyles({ iconColor: task?.icon?.color, iconBcColor: task?.icon?.bcColor });

  const handleConfigureClick = (pathname) => {
    window.location.href = `${window.location.origin}${pathname}`;
  };

  const showVideoPlayer = task?.video?.includes('mp4');

  return (
    <Accordion
      key={task?.id}
      customHandler
      isOpen={open === task?.id}
      onOpen={(value: any) => {
        if (value?.id === open) {
          setOpen('');
        } else {
          setOpen(value?.id);
        }
      }}
      value={task}
      containerStyles={classes.accordionContainer}
      title={
        <div className={classes.accordionTitleContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.iconContainer}>
              {task?.icon?.name === 'logo' ? (
                <img src={logoIcon} alt="" />
              ) : (
                <Icon iconName={task?.icon?.name} className={classes.icon} />
              )}
            </div>
            <span className={classes.accordionTitle}>{task?.title}</span>
          </div>
          <Icon
            iconName="check_circle"
            className={classnames({
              [classes.defaultIcon]: true,
              [classes.completedIcon]: task?.status?.completed,
            })}
          />
        </div>
      }
      collapseContent={
        <div>
          <div className={classes.taskDescription}>{task?.description}</div>
          {showVideoPlayer ? (
            <VideoPlayer url={task?.video} />
          ) : (
            <div className={classes.imageContainer}>
              <img border-radius="16px" width="100%" height="auto" src={task?.video} />
            </div>
          )}
          <div className={classes.buttonsContainer}>
            {!isConsumer && (
              <Button
                styleClass="transparentGreenText"
                width="fit-content"
                marginRight="17px"
                disabled={task?.status?.completed || loading === task?.id}
                onClick={() => handleCompleteTask(task?.id, { status: 'completed' })}
              >
                {loading === task?.id ? <Loader size={18} padding="0" /> : 'Mark as complete'}
              </Button>
            )}

            {isConsumer ? (
              <Button
                styleClass="mainButton"
                width="94px"
                onClick={() => handleCompleteTask(task?.id, { status: 'completed' })}
              >
                Continue
              </Button>
            ) : (
              <Button
                styleClass="transparentBtn"
                width="94px"
                onClick={() => handleConfigureClick(task?.action)}
              >
                Configure
              </Button>
            )}
          </div>
        </div>
      }
      arrowBtnStyles={classes.arrowBtnStyles}
      arrowBtnStylesOpen={classes.arrowBtnStylesOpen}
    />
  );
};

export default GettingStartedCard;
