import React, { useCallback } from 'react';
import { DataGrid, useServerPagination } from '@zaveit/uikit';
import isEqual from 'lodash.isequal';

import { useSubscriptionTableConfig } from 'hooks/tables/useSubscriptionTableConfig';

import useStyles from './SubscriptionTable.styles';
import { tableNames, rowsPerPageOptions, defaultSortModels } from '../constants';

const SubscriptionTable = ({ subscription, handleGetData, consumptions, loading, tenant }) => {
  const classes = useStyles();

  const { headCellsSubscription, headCellsProduct } = useSubscriptionTableConfig(consumptions);
  const { tableConfig, handleChangePage, handleChangeRowsPerPage, handleSortingModelChange } =
    useServerPagination({
      defaultConfig: {
        offset: 0,
        limit: rowsPerPageOptions[0],
        sortModel: defaultSortModels.subscription,
      },
      tableName: tableNames.subscription,
      handleGetData,
      tenant,
    });

  const getDetailPanelHeight = useCallback(() => 'auto' as 'auto', []);

  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const rows = row?.order_details?.items;
      return (
        rows?.length > 0 && (
          <div className={classes.innerTableContainer}>
            <DataGrid
              name="dashboardApp_subscription_product"
              columns={headCellsProduct}
              rows={rows}
              emptyViewConfig={{
                iconName: 'list_alt',
                title: 'No data',
              }}
              headerHeight={40}
              hasHeader={false}
              minHeight="50"
              hasShadow={false}
              sectionPadding="0"
              pagination={false}
              getRowId={(inputRow) => inputRow._id}
            />
          </div>
        )
      );
    },
    [classes.innerTableContainer, headCellsProduct],
  );

  return (
    <DataGrid
      name={tableNames.subscription}
      columns={headCellsSubscription}
      rows={subscription?.data || []}
      initialState={{
        sortingField: tableConfig?.sortModel?.field,
        sortingOrder: tableConfig?.sortModel?.sort,
      }}
      emptyViewConfig={{
        iconName: 'list_alt',
        title: 'No data',
      }}
      getRowId={(row) => row._id}
      hideExport
      hideSearch
      sortingMode="server"
      handleSortingModelChange={handleSortingModelChange}
      serverSortModel={tableConfig?.sortModel}
      paginationMode="server"
      onPageChange={handleChangePage}
      rowCount={subscription?.pagination?.total || 0}
      rowsPerPage={tableConfig?.limit}
      rowsPerPageOptions={rowsPerPageOptions}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      hasHeader={false}
      hasShadow={false}
      loading={loading}
      sectionPadding="0 8px"
    />
  );
};

export default React.memo(SubscriptionTable, isEqual);
