import axios from 'axios';

import Config from 'config/index';

const instance = axios.create({
    baseURL: Config.baseTrainingUrl,
    withCredentials: true,
});

export const getTrainingCourses = async ({
    limit = 2,
    offset = 0,
}) => {
    try {
        const { data } = await instance.get('/v1/training/course', {
            params: {
                limit,
                offset,
                sort: '-updated',
            },
        });
        return data;
    } catch (e) {
        console.error(e);
    }
};

export const getAdminDetailedCourses = async ({
    filter = '',
    tenant,
    limit = undefined,
    offset = 0,
    sort = undefined,
}) => {
    const { data } = await instance.get(`/v1/training/admin/course/detailed${filter}`, {
        params: {
            'filter[tenant]': tenant,
            limit,
            offset,
            sort,
        },
    });
    return data;
};
