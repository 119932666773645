import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import Button from 'components/shared/Button/Button';
import { updateTableFinding } from 'redux/findings/findings.actions';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { acceptFindings, resolveFindings } from '../../api-client/risc';
import { Tabs } from './constants';
import useStyles from './FindingsTable.styles';

type TFindingProps = {
  tab: Tabs;
  ids: string[];
  handleGetStatistics: () => void;
  setSelectedFindingsIds: (value: any[]) => void;
};

const FindingsActions = ({
  tab,
  ids,
  handleGetStatistics,
  setSelectedFindingsIds,
}: TFindingProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);

  const shouldFilter = tab === Tabs.ACCEPTED || tab === Tabs.RESOLVED || tab === Tabs.OPEN;
  const handleAccept = async () => {
    try {
      setLoadingAccept(true);
      await acceptFindings(ids);
      setSelectedFindingsIds([]);
      handleGetStatistics();
      dispatch(updateTableFinding({ ids, status: 'accepted', shouldFilter }));
    } catch (err) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not accept!',
          open: true,
        }),
      );
    }
    setLoadingAccept(false);
  };

  const handleResolve = async () => {
    try {
      setLoading(true);
      await resolveFindings(ids);
      setSelectedFindingsIds([]);
      dispatch(updateTableFinding({ ids, status: 'resolved', shouldFilter }));
      handleGetStatistics();
    } catch (err) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not report!',
          open: true,
        }),
      );
    }
    setLoading(false);
  };

  return (
    <div className={classes.buttonsContainer}>
      {tab !== Tabs.ACCEPTED && (
        <Button styleClass="activeGreenBordered" marginRight="12px" onClick={handleAccept}>
          {loadingAccept ? <CircularProgress size={16} className={classes.spiner} /> : 'Accept'}
        </Button>
      )}
      {tab !== Tabs.RESOLVED && (
        <Button styleClass="activeGreenBordered" onClick={handleResolve}>
          {loading ? <CircularProgress size={16} className={classes.spiner} /> : 'Resolve'}
        </Button>
      )}
    </div>
  );
};

export default FindingsActions;
