import { getRisc, getFindings } from 'api-client/risc';
import { getCustomers } from 'api-client/user';
import { Ranges, ServicesStatuses, DefaultSortingValue } from 'components/constants';
import {
  getExtendedAccount,
  getAccounts,
  getCustomerSeries as getCustomerHistory,
} from 'api-client/account';
import { getBackupTargets, getVsaasTargets } from 'api-client/target';
import { getCustomerTickets, getTicketHistory, getTicketsAfterDate } from 'api-client/support';
import { TFinding } from 'components/types';
import dayjs from 'dayjs';
import { getDeliveryByStatus } from 'api-client/delivery';
import { TileData, DailyStatusItem } from 'types/home';
import { SalesHistory } from 'types/order';
import { CustomerHistory } from 'types/account';
import { TicketHistory } from 'types/support';
import { getOrderHistory, getOrderByStatus } from '../../api-client/order';
import { getFindingByRisc, getRiscHistory } from '../../api-client/risc';

export const getDashboardData = async (tenant, isMsp) => {
  try {
    const riscPromise = getRisc(tenant);
    const riscHistoryPromise = getRiscHistory({
      tenant,
      date: dayjs().subtract(7, 'day').format(),
      now: true,
    });
    const findingsPromise = getFindings(tenant, null, null, 100);
    const ticketPromise = getCustomerTickets({ status: 'open', tenant });
    const salesHistoryPromise = getOrderHistory({ days: 14 });
    const customerHistoryPromise = getCustomerHistory({ days: 14 });
    const ticketHistoryPromise = getTicketHistory({ days: 14, tenant });
    const newTicketsPromise = getTicketsAfterDate({ date: dayjs().format('YYYY-MM-DD'), tenant });
    const acceptedOrderPromise = getOrderByStatus({ tenant, status: 'accepted' });
    const openDeliveryPromise = getDeliveryByStatus({ status: 'open' });
    const findingByRiscPromise = getFindingByRisc({ tenant, status: 'open', risc: 8 });

    if (isMsp) {
      const customersPromise = getCustomers();
      const accountsPromise = getAccounts({ tenant });

      const [
        riscData,
        riscHistoryData,
        findingData,
        customersData,
        accountsData,
        ticketsData,
        salesHistoryData,
        customerHistoryData,
        ticketHistoryData,
        acceptedOrderData,
        openDeliveryData,
        findingByRiscData,
        newTicketsData,
      ] = await Promise.all([
        riscPromise,
        riscHistoryPromise,
        findingsPromise,
        customersPromise,
        accountsPromise,
        ticketPromise,
        salesHistoryPromise,
        customerHistoryPromise,
        ticketHistoryPromise,
        acceptedOrderPromise,
        openDeliveryPromise,
        findingByRiscPromise,
        newTicketsPromise,
      ]);

      // Array with items for the "daily task" section in the Providers homepage.
      const dailyStatus: DailyStatusItem[] = [
        {
          title: 'Orders',
          message: 'to confirm',
          count: acceptedOrderData.length,
          redirectPath: 'account-manager/orders',
        },
        {
          title: 'Open tickets',
          message: 'to review',
          count: ticketsData.length,
          redirectPath: 'v2/support/customer-tickets/',
        },
        {
          title: 'Open deliveries',
          message: 'to review',
          count: openDeliveryData.length,
          redirectPath: 'account-manager/delivery/dashboard',
        },
        {
          title: 'Critical findings',
          message: 'need attention',
          count: findingByRiscData.data.length,
          redirectPath: 'dashboard/findings',
        },
      ];

      const newRiscHistoryData = riscHistoryData.reduce<TileData>(
        (acc, item) => {
          acc.amount.push(item.risc_score);
          acc.data.push(item.risc_score);
          acc.date.push(item.date);
          return acc;
        },
        { data: [], date: [], amount: [] },
      );

      const newSalesHistoryData = salesHistoryData.reduce(
        (acc: TileData, item: SalesHistory) => {
          acc.amount.push(item.confirmedCount);
          acc.data.push(item.confirmedAmount);
          acc.date.push(item.date);
          return acc;
        },
        { data: [], date: [], amount: [] },
      );

      const newCustomerHistoryData = customerHistoryData.reduce(
        (acc: TileData, item: CustomerHistory) => {
          acc.amount.push(item.value);
          acc.data.push(item.total);
          acc.date.push(item.date);
          return acc;
        },
        { data: [], date: [], amount: [] },
      );

      const newTicketHistoryData = ticketHistoryData.reduce(
        (acc: TileData, item: TicketHistory) => {
          acc.amount.push(newTicketsData.length);
          acc.data.push(item.totalOpen);
          acc.date.push(item.date);
          return acc;
        },
        { data: [], date: [], amount: [] },
      );

      return {
        risc: riscData,
        riscHistory: newRiscHistoryData,
        findings: findingData,
        criticalFindings: findingByRiscData,
        customers: customersData,
        accounts: accountsData?.data,
        tickets: ticketsData,
        salesHistory: newSalesHistoryData,
        customerHistory: newCustomerHistoryData,
        ticketHistory: newTicketHistoryData,
        dailyStatus,
      };
    }

    const backupPromise = getBackupTargets();
    const vsaasPromise = getVsaasTargets();

    const [riscData, findingData, backupData, vsaasData, ticketsData] = await Promise.all([
      riscPromise,
      findingsPromise,
      backupPromise,
      vsaasPromise,
      ticketPromise,
    ]);

    return {
      risc: riscData,
      findings: findingData,
      backup: backupData,
      vsaas: vsaasData,
      tickets: ticketsData,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const getProviderTileData = async (tenant) => {
  try {
    const ticketPromise = getCustomerTickets({ status: 'open', tenant });
    const salesHistoryPromise = getOrderHistory({ days: 14 });
    const customerHistoryPromise = getCustomerHistory({ days: 14 });
    const ticketHistoryPromise = getTicketHistory({ days: 14, tenant });
    const newTicketsPromise = getTicketsAfterDate({ date: dayjs().format('YYYY-MM-DD'), tenant });

    const customersPromise = getCustomers();
    const accountsPromise = getAccounts({ tenant });

    const [
      customersData,
      accountsData,
      ticketsData,
      salesHistoryData,
      customerHistoryData,
      ticketHistoryData,
      newTicketsData,
    ] = await Promise.all([
      customersPromise,
      accountsPromise,
      ticketPromise,
      salesHistoryPromise,
      customerHistoryPromise,
      ticketHistoryPromise,
      newTicketsPromise,
    ]);

    const newSalesHistoryData = salesHistoryData.reduce(
      (acc: TileData, item: SalesHistory) => {
        acc.amount.push(item.confirmedCount);
        acc.data.push(item.confirmedAmount);
        acc.date.push(item.date);
        return acc;
      },
      { data: [], date: [], amount: [] },
    );

    const newCustomerHistoryData = customerHistoryData.reduce(
      (acc: TileData, item: CustomerHistory) => {
        acc.amount.push(item.value);
        acc.data.push(item.total);
        acc.date.push(item.date);
        return acc;
      },
      { data: [], date: [], amount: [] },
    );

    const newTicketHistoryData = ticketHistoryData.reduce(
      (acc: TileData, item: TicketHistory) => {
        acc.amount.push(newTicketsData.length);
        acc.data.push(item.totalOpen);
        acc.date.push(item.date);
        return acc;
      },
      { data: [], date: [], amount: [] },
    );

    return {
      customers: customersData,
      accounts: accountsData?.data,
      tickets: ticketsData,
      salesHistory: newSalesHistoryData,
      customerHistory: newCustomerHistoryData,
      ticketHistory: newTicketHistoryData,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const getCustomersData = async (tenant) => {
  try {
    const riscPromise = getRisc(tenant, true);
    const customersPromise = getExtendedAccount(tenant);

    const [riscData, customersData] = await Promise.all([riscPromise, customersPromise]);

    return {
      risc: riscData,
      customers: customersData?.data,
    };
  } catch (error) {
    return error;
  }
};

export const updateProviderFindingsData = (findings, customers) =>
  findings?.slice(Ranges.tables.min, Ranges.tables.max).map((finding) => ({
    ...finding,
    customer: customers?.find((customer) => customer.id === finding.metadata.child_tenant)?.name,
  }));

export const updateConsumerFindingsData = (findings, targets) => {
  return findings?.slice(Ranges.tables.min, Ranges.tables.max).map((finding) => ({
    ...finding,
    target: targets.find((target) => target._id === finding.metadata.target)?.title,
  }));
};

export const getTargetsData = (findingsData, backup = [], vsaas = []) => {
  const targetsData = [...backup, ...vsaas];
  const findings = new Map<string, TFinding>(
    findingsData?.map((finding) => [finding?.metadata?.target, finding]),
  );

  return targetsData
    ?.map((target) => {
      const currFinding = findings.get(target._id);

      return {
        _id: target?.id,
        title: target?.displayName || target?.name,
        user: target?.account?.name,
        metadata: { application: currFinding?.metadata?.application || 'default' },
        service: ServicesStatuses[currFinding?.metadata?.application]?.title,
        risc_score: currFinding?.risc_score,
      };
    })
    .sort((a, b) => (b.risc_score || DefaultSortingValue) - (a.risc_score || DefaultSortingValue))
    .slice(Ranges.tables.min, Ranges.tables.max);
};
