import { useCallback, useEffect } from 'react';
import { DataGrid } from '@zaveit/uikit';

import { usePropertyTableConfig } from 'hooks/tables/usePropertyTableConfig';

import useStyles from './PropertyTable.styles';
import { tableNames } from '../constants';

const PropertyTable = ({
  property,
  period,
  handleGetData,
  consumptions,
  propertiesLoading,
  tenant,
}) => {
  const classes = useStyles();

  const { headCellsProperty, headCellsProduct } = usePropertyTableConfig(consumptions);

  useEffect(() => {
    handleGetData({ period, tenant });
  }, [tenant, period, handleGetData]);

  const getDetailPanelHeight = useCallback(() => 'auto' as 'auto', []);

  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const rows = row.items;
      return (
        rows?.length > 0 && (
          <div className={classes.innerTableContainer}>
            <DataGrid
              name="dashboardApp_property_product"
              columns={headCellsProduct}
              rows={rows}
              emptyViewConfig={{
                iconName: 'list_alt',
                title: 'No data',
              }}
              hasHeader={false}
              getRowId={(rowitem) => rowitem?.name || rowitem?._id}
              hasShadow={false}
              sectionPadding="0"
              pagination={false}
              headerHeight={0}
            />
          </div>
        )
      );
    },
    [classes.innerTableContainer, headCellsProduct],
  );

  return (
    <DataGrid
      name={tableNames.property}
      columns={headCellsProperty}
      rows={property?.data || []}
      initialState={{
        sortingField: 'name',
        sortingOrder: 'asc',
      }}
      emptyViewConfig={{
        iconName: 'list_alt',
        title: 'No data',
      }}
      hideExport
      hideSearch
      rowCount={property?.pagination?.total || 0}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      hasHeader={false}
      hasShadow={false}
      sectionPadding="0 8px"
      loading={propertiesLoading}
      getRowId={(row) => row?.name || row?._id}
    />
  );
};

export default PropertyTable;
