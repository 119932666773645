export const ChartColors = [
  '#FFC107', // yellow
  '#FF6F07', // orange
  '#FF4842', // red
  '#E13672', // pink
  '#B93BD8', // purple
  '#754BF2', // dark purple
  '#3F62F1', // dark blue
  '#1890FF', // blue
  '#0FB5CE', // aqua
  '#12AE80', // dark green
  '#5FBB50', // green
  '#7CC615', // light green
];
