export const cookieGreetings = [
    'Bingo bango, mango-shaped cookie!',
    'Abra-ca-dabra! A cookie appears!',
    'Surprise treat unlocked!',
    'Cookie deployment successful!',
    'Here comes a cookie for you!',
    'Your cookie is served.',
    'Well done, hacker! Enjoy your cookie!',
    'Your treat teleported to you!',
    "You've got a treat!",
    'Alert: A wild cookie appeared!',
];

export const noCookieMessages = [
    'Oops, cookie jar is empty!',
    'No cookie today, try again!',
    'Sorry, cookies on strike today.',
    'Who stole the cookies? Not you!',
    'All cookies devoured... none left!',
    'Cookies escaped, capture them!',
    'Your cookie crumbled before arrival.',
    'No treats, just tricks today!',
    '404 Cookie Not Found!',
    'Denied! No cookies in vault.',
];
