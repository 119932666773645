import { setSelectedAccountView } from 'redux/account/account.actions';

export const handleGetRightSidebarData = ({
  activeTab,
  setSearch,
  search,
  sideBarViewAccounts,
  selectedAccountView,
  dispatch,
  history,
  selectedHelpBlock,
  handleOpenRightSidebar,
  userInfo,
}) => {
  const showAccountTab = Boolean(userInfo?.child_tenants?.length);

  const accountsTab = {
    tab: 'Accounts',
    showSearch: true,
    setSearchString: (value) => setSearch(value),
    searchString: search,
    content: sideBarViewAccounts,
    selectedOption: selectedAccountView,
    handleDismiss: () => {},
    handleReset: () => {},
    bottomActions: [
      {
        title: 'Reset',
        onClick: () => {
          dispatch(setSelectedAccountView(null));
          setSearch('');
        },
        className: 'resetBtn',
        disabled: !(selectedAccountView || search),
      },
      {
        title: 'Dismiss',
        onClick: () => handleOpenRightSidebar(),
        className: 'dismissBtn',
      },
    ],
  };

  const rightSidebarData = {
    activeTab,
    contentData: [accountsTab],
  };

  if (showAccountTab) {
    rightSidebarData.contentData;
  }
  return rightSidebarData;
};
