export const handleServiceClick = ({ service, selectedFinding, userInfo }) => {
  if (service === 'order') {
    const shortId = selectedFinding?.metadata?.target;
    window.open(
      `${window.location.origin}/account-manager/delivery/dashboard?search=${shortId}`,
      '_self',
    );
  } else if (service === 'training') {
    const tenant = selectedFinding?.metadata?.tenant;
    const { tenant: myTenant, child_tenants: childTenants } = userInfo;

    if (tenant === myTenant) {
      window.open(`${window.location.origin}/training/admin`, '_self');
    } else if (childTenants.includes(tenant)) {
      window.open(`${window.location.origin}/training/customer-admin`, '_self');
    }
  } else {
    window.open(`${window.location.origin}/${service}`, '_self');
  }
};
