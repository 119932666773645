import { makeStyles } from '@material-ui/core';

import Colors from 'styles/Color.styles';

const useStyles = makeStyles(() => ({
  innerTableContainer: {
    border: `1px solid ${Colors.shadowGrey}`,
    borderRadius: '16px',
    padding: '8px',
  },
}));

export default useStyles;
