import { Icon } from '@zaveit/icons';

import ColorMarker from './ColorMarker';
import useStyles from './Timeline.styles';

const TimelineLegend = ({ properties = [], handleChangeProperties }) => {
  const classes = useStyles({});

  const propertiesTitles = properties.map((property) => property.title.split(',')[0]);

  return (
    <div className={classes.legend}>
      {properties.map((property) => (
        <div className={classes.marker} key={property.title}>
          <ColorMarker color={property.color} />
          {property.title}
          <div
            role="button"
            aria-hidden="true"
            onClick={() =>
              handleChangeProperties({
                target: {
                  value: propertiesTitles.filter((title) => title !== property.title.split(',')[0]),
                },
              })
            }
          >
            <Icon iconName="cancel" className={classes.deleteIcon} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimelineLegend;
