import { Typography } from '@material-ui/core';

import { Ranges } from 'components/constants';

import useStyles from './Score.styles';

type TScore = {
  score: number;
};

const Score = ({ score }: TScore) => {
  const classes = useStyles();

  const defaultScore = '-';
  const isGreen = score >= Ranges.score.green.min && score < Ranges.score.green.max;
  const isYellow = score >= Ranges.score.yellow.min && score < Ranges.score.yellow.max;
  const isRed = score >= Ranges.score.red.min && score <= Ranges.score.red.max;

  return (
    <div className={classes.container}>
      {!score && score !== 0 && <Typography className={classes.green}>{defaultScore}</Typography>}
      {isGreen && <Typography className={classes.green}>{score}</Typography>}
      {isYellow && <Typography className={classes.yellow}>{score}</Typography>}
      {isRed && <Typography className={classes.red}>{score}</Typography>}
    </div>
  );
};

export default Score;
