import { all } from 'redux-saga/effects';

import targetsApi from './targets/targets-api.sagas';
import authApi from './auth/auth-api.sagas';
import findingsApi from './findings/findings-api.sagas';
import accountApi from './account/account-api.sagas';

function* rootSaga() {
  yield all([targetsApi(), authApi(), findingsApi(), accountApi()]);
}

export default rootSaga;
