import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { DailyStatusItem } from 'types/home';
import classNames from 'classnames';
import useStyles from './Task.styles';

interface TaskItemProps {
  data: DailyStatusItem;
  lastIndex?: number;
}

export default function TaskItem({ data, lastIndex }: TaskItemProps) {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      className={classes.noTextDecoration}
      onClick={() => {
        window.location.href = `https://${window.location.host}/${data.redirectPath}`;
      }}
      tabIndex={0}
    >
      <div
        className={classNames({
          [classes.firstTaskItem]: lastIndex === 0,
          [classes.lastTaskItem]: lastIndex > 0,

          [classes.taskItem]: !lastIndex,
        })}
      >
        <div className={classes.taskContainer}>
          <Typography variant="subtitle1">{data.title}</Typography>
          <Typography variant="body1" className={classes.textSecondaryColor}>
            {data.message}
          </Typography>
          <div className={classes.chip}> {data.count}</div>
        </div>
        <div>
          <ChevronRight />
        </div>
      </div>
    </div>
  );
}
