import { resolveFinding, acceptFinding, reportFinding } from 'api-client/risc';
import { getCurrentTenantAccount } from 'api-client/account';
import { updateTableFinding } from 'redux/findings/findings.actions';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';

import { Tabs } from './constants';

type Arguments = {
  id: string;
  dispatch?: any;
  handleSuccess?: any;
  isRelated?: boolean;
  tab?: number;
};

export const handleAcceptFinding = async ({
  id,
  dispatch,
  handleSuccess,
  isRelated = false,
  tab = null,
}: Arguments) => {
  const shouldFilter = tab !== Tabs.FALSE_POSITIVE;
  const resp = await acceptFinding(id, dispatch);
  if (resp && dispatch) {
    if (!isRelated) {
      dispatch(updateTableFinding({ id, status: 'accepted', shouldFilter }));
    }
    dispatch(
      setStatusBar({
        type: 'success',
        message: 'Finding was accepted!',
        open: true,
      }),
    );
  }
  if (handleSuccess) handleSuccess(resp);
};

export const handleReportFinding = async ({ id, dispatch, handleSuccess }: Arguments) => {
  const resp = await reportFinding(id, dispatch);
  if (resp && dispatch) {
    dispatch(
      setStatusBar({
        type: 'success',
        message: 'Finding was successfully reported!',
        open: true,
      }),
    );
  }
  if (handleSuccess) handleSuccess(resp);
};

export const handleResolveFinding = async ({
  id,
  dispatch,
  handleSuccess,
  isRelated,
  tab = null,
}: Arguments) => {
  const shouldFilter = tab !== Tabs.FALSE_POSITIVE;
  const resp = await resolveFinding(id, dispatch);
  if (resp && dispatch) {
    if (!isRelated) {
      dispatch(updateTableFinding({ id, status: 'resolved', shouldFilter }));
    }
    dispatch(
      setStatusBar({
        type: 'success',
        message: 'Finding was resolved!',
        open: true,
      }),
    );
  }
  if (handleSuccess) handleSuccess(resp);
};

export const handleGetAccount = async (tenant, setRelatedAccount) => {
  try {
    const resp = await getCurrentTenantAccount(tenant);
    setRelatedAccount(resp?.data[0]);
  } catch (err) {
    setRelatedAccount(null);
  }
};

export const handleGetSearchData = (values, search) => {
  return values
    ?.slice()
    ?.filter(
      (finding) =>
        finding?.name?.toLowerCase().includes(search.toLowerCase()) ||
        finding?.application?.toString().includes(search) ||
        finding?.description?.toLowerCase().includes(search.toLowerCase()),
    );
};
