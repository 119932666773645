import { handleActions } from 'redux-actions';
import { getData, saveData } from 'helpers/localStorageHelper';
import {
  SET_ACCOUNT_CREATION_STATUS,
  GET_ACCOUNTS_SUCCESS,
  UPDATE_ACCOUNTS_LIST,
  SET_SELECTED_ACCOUNT_VIEW,
  GET_SELECTED_VIEW_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_REQUEST,
} from './account.actions';
import { Account } from '../types';

// ------------------------------------
// Reducer
// ------------------------------------

interface AccountState {
  isCreationStarted: boolean;
  accountsList: Account[];
  loading: boolean;
}

const initialState = {
  isCreationStarted: false,
  accountsList: [],
  accountsListSelectedView: [],
  selectedAccountView: getData('selectedAccountView') || null,
  loading: false,
} as AccountState;

export default handleActions(
  {
    [SET_ACCOUNT_CREATION_STATUS]: (state, { payload }) => {
      return {
        ...state,
        isCreationStarted: payload,
      };
    },
    [GET_ACCOUNTS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        accountsList: payload,
        loading: false,
      };
    },
    [GET_ACCOUNTS_FAILURE]: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    [GET_ACCOUNTS_REQUEST]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [GET_SELECTED_VIEW_ACCOUNTS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        accountsListSelectedView: payload,
      };
    },
    [SET_SELECTED_ACCOUNT_VIEW]: (state, { payload }) => {
      let account;
      if (payload?._id !== state.selectedAccountView?._id) {
        account = payload;
      } else {
        account = null;
      }
      saveData('selectedAccountView', account);
      return {
        ...state,
        selectedAccountView: account,
      };
    },
    [UPDATE_ACCOUNTS_LIST]: (state, { payload }) => {
      const doesAccountExist = state.accountsList.find((account) => account._id === payload._id);
      let newAccountList = [];
      if (doesAccountExist) {
        newAccountList = state.accountsList.map((account) => {
          if (account._id === payload._id) {
            return payload;
          }
          return account;
        });
      } else {
        newAccountList = [...state.accountsList, payload];
      }
      return {
        ...state,
        accountsList: newAccountList,
      };
    },
  },
  initialState,
);
