import { Card, CardContent, IconButton, Tooltip, Typography } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';
import { TrendingDown, TrendingUp } from '@material-ui/icons';
import { Button, Colors } from '@zaveit/uikit';
import ReactApexChart from 'react-apexcharts';
import 'styles/fonts.scss';
import { useEffect, useMemo, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import { useHistory } from 'react-router-dom';
import { TileData } from 'types/home';
import { TFinding } from 'components/types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useStyles from './DataTile.styles';

interface DataTileRiscProps {
  isLoading: boolean;
  title: string;
  data: TileData;
  message: string;
  tooltipLabel: string;
  redirectPath: string;
  findingsData: any;
  findings: TFinding[];
}

/**
 * This component contains a chart and needs an object with timeseries data.
 * @param {object} data Object with date[], data[], amount[] to display timeseries data.
 * @param {string} tooltipLabel Value displayed on the toolitp in the chart line (ie. "sales").
 *
 */

function DataTileRISC({
  isLoading,
  title,
  data,
  message,
  findingsData,
  tooltipLabel,
  redirectPath,
  findings,
}: DataTileRiscProps) {
  const classes = useStyles();
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFF');
  const [primaryColor, setPrimaryColor] = useState<string>(Colors.blue500);
  const [chartShade, setChartShade] = useState<string>('light');
  const [textColor, setTextcolor] = useState<string>(Colors.backgroundGrey);
  const [isTrendNegative, setIsTrendNegative] = useState<boolean>(false);
  const [percentageChange, setPercentageChange] = useState<number | null>(null);
  const [criticalFindings, setCriticalFindings] = useState<number>(null);

  let history = useHistory();

  const currentRisc = useMemo(() => {
    if (findings.length === 0) return 0;
    const totalRiscScore = findings.reduce((sum, finding) => sum + finding.risc_score, 0);
    return totalRiscScore / findings.length;
  }, [findings]);

  // Percentage change calculation
  useEffect(() => {
    if (isLoading) return;
    if (data?.data.length < 2) {
      setPercentageChange(null);
    } else {
      const secondToLastValue = data?.data.slice(-2).shift();
      const lastValue = data?.data.slice(-1).pop();

      if (secondToLastValue === 0 && lastValue === 0) {
        return setPercentageChange(0);
      }

      const change = parseFloat(
        (((lastValue - secondToLastValue) / secondToLastValue) * 100).toFixed(1),
      );

      setIsTrendNegative(change < 0);
      setPercentageChange(change);
      setCriticalFindings(findingsData?.data?.length);
    }
  }, [isLoading, data, findingsData]);

  useEffect(() => {
    if (currentRisc < 5) {
      setBackgroundColor(Colors.white);
      setPrimaryColor(Colors.brand500);
      setChartShade('light');
      setTextcolor(Colors.backgroundGrey);
    }
    if (currentRisc >= 5) {
      setBackgroundColor(Colors.yellow950);
      setPrimaryColor(Colors.yellow500);
      setChartShade('dark');
      setTextcolor(Colors.white);
    }
    if (currentRisc >= 7.5) {
      setBackgroundColor(Colors.red950);
      setPrimaryColor(Colors.red500);
      setChartShade('dark');
      setTextcolor(Colors.white);
    }
  }, [currentRisc]);

  const chartData = [
    {
      name: tooltipLabel,
      data: data?.data,
      type: 'area',
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: chartShade,
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0.1,
        stops: [0, 100],
        type: 'vertical',
        inverseColors: false,
      },
    },
    colors: [primaryColor],
    labels: null,
    xaxis: {
      labels: {
        show: false,
      },
      categories: data?.date,

      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
        width: 0,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
  };

  return (
    <div>
      <Card style={{ backgroundColor }} className={classes.container}>
        <CardContent className={classes.content}>
          <div className={classes.topSection}>
            <div className={classes.titleTrendRow}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                <Typography style={{ color: textColor }} variant="subtitle2">
                  {title}
                </Typography>
                <Tooltip title="Click to learn more" style={{ color: '#FFF' }}>
                  <IconButton
                    size="small"
                    aria-label="learn more"
                    onClick={() =>
                      window.open(
                        'https://docs.zaveit.io/docs/product/introduction/risc-management',
                        '_blank',
                      )
                    }
                  >
                    <InfoOutlinedIcon fontSize="small" style={{ color: textColor }} />
                  </IconButton>
                </Tooltip>
              </div>

              <div className={classes.trendSection}>
                {isLoading ? (
                  <Skeleton width={50} height={25} />
                ) : percentageChange === 0 ? (
                  <>
                    <>
                      <TrendingUp
                        style={{ color: Colors.grey500, marginRight: '2px' }}
                        fontSize="small"
                      />
                      <Typography variant="caption" style={{ color: Colors.grey500 }}>
                        {percentageChange}%
                      </Typography>
                    </>
                  </>
                ) : (
                  <>
                    {isTrendNegative ? (
                      <>
                        <TrendingDown style={{ color: '#00C9A7' }} fontSize="small" />
                        <Typography variant="caption" style={{ color: '#00C9A7' }}>
                          -{percentageChange}%
                        </Typography>
                      </>
                    ) : (
                      <>
                        <TrendingUp style={{ color: '#FF4842' }} fontSize="small" />
                        <Typography variant="caption" style={{ color: '#FF4842' }}>
                          +{percentageChange}%
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              {isLoading ? (
                <Skeleton width={100} height={40} />
              ) : (
                <Typography variant="h4" style={{ color: textColor }}>
                  {currentRisc.toFixed(1)}
                </Typography>
              )}
            </div>
          </div>
          {isLoading ? (
            <div className={classes.chartSkeleton}>
              <Skeleton width="100%" height={110} />
            </div>
          ) : (
            <div className={classes.chartSection}>
              <ReactApexChart series={chartData} height={90} width="100%" options={chartOptions} />
            </div>
          )}

          {isLoading ? (
            <div className={classes.bottomSection}>
              <Skeleton width={75} height={30} />
              <Button styleClass="blue">View findings</Button>
            </div>
          ) : (
            <div className={classes.bottomSection}>
              <Typography variant="caption" style={{ color: textColor }}>
                {criticalFindings} {message}
              </Typography>
              <Button
                style={{ color: currentRisc < 5 ? Colors.blue500 : primaryColor }}
                styleClass="blue"
                onClick={() => history.push(redirectPath)}
              >
                View findings
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default DataTileRISC;
