import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveData } from 'helpers/localStorageHelper';

import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { updateGettingStartedTask, updateGettingStartedStatus } from 'api-client/user';
import GettingStartedCard from './GettingStartedCard';
import useStyles from './GettingStarted.styles';

const GettingStarted = ({ data, setIsSidebarOpened, setShowGettingStarted }) => {
  const classes = useStyles({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const [open, setOpen] = useState('');
  const [loading, setLoading] = useState('');
  const firstInComplettedTask = data?.tasks?.find((item) => !item?.status?.completed);
  const isConsumer = data?.accountrole === 'consumer';

  useEffect(() => {
    setTasks(data?.tasks);
    if (data?.tasks?.length) {
      setOpen(firstInComplettedTask?.id);
    }
  }, [data?.tasks, firstInComplettedTask]);

  const handleCompleteTask = async (id, value) => {
    try {
      setLoading(id);
      const response = await updateGettingStartedTask(id, value);
      const newTasks = tasks?.map((item) => {
        if (item?.id === id) {
          return { ...item, status: { completed: true } };
        }
        return item;
      });
      setTasks(newTasks);
      dispatch(
        setStatusBar({
          type: 'success',
          message: 'Step marked as complete.',
          open: true,
        }),
      );
      const currTaskIndex = tasks?.findIndex((item) => item?.id === id);
      const nextTask = tasks[currTaskIndex + 1];
      setOpen(nextTask?.id);
      if (response?.data?.status?.completed) {
        history.push('/dashboard');
        setShowGettingStarted(false);
        setIsSidebarOpened(true);
        saveData('sidebarOpen', true);
      }
    } catch (err) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Unable to complete step. Please try again. ',
          open: true,
        }),
      );
    }
    setLoading('');
  };

  const handleSkipAllTasks = async (status) => {
    try {
      await updateGettingStartedStatus({ status });
      history.push('/dashboard');
      setIsSidebarOpened(true);
      setShowGettingStarted(false);
      saveData('sidebarOpen', true);
    } catch (err) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Unable to skip all steps. Please try again.',
          open: true,
        }),
      );
    }
  };

  return (
    <div className={classes.gettingStartedContainer}>
      <div className={classes.title}>{data?.title}</div>
      <div className={classes.description}>{data?.description}</div>
      <div className={classes.tasksContainer}>
        {tasks?.map((task) => {
          return (
            <GettingStartedCard
              isConsumer={isConsumer}
              task={task}
              handleCompleteTask={handleCompleteTask}
              open={open}
              setOpen={setOpen}
              loading={loading}
            />
          );
        })}
      </div>
      {!isConsumer && (
        <button
          type="button"
          className={classes.linkBtn}
          onClick={() => handleSkipAllTasks(!firstInComplettedTask ? 'completed' : 'dismissed')}
        >
          Skip and continue to your dashboard
        </button>
      )}
    </div>
  );
};

export default GettingStarted;
