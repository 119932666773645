export default {
  subtitleGrey: '#687285',
  mainGreen: '#5fbb50',
  mainLightBlue: '#116FDB',
  hoverDarkGreen: '#469339',
  lightenGreen: '#E9F4E7',
  mediumGreen: '#A5D399',
  darkGreen: '#5DBA50',
  darkestGrey: '#52545E',
  mainGrey: '#A7A7AF',
  darkGrey: '#67676B',
  darkest2Grey: '#424448',
  iconBackgroundGrey: 'rgba(148, 157, 176, 0.16)',
  backgroundLightGrey: 'rgba(148, 157, 176, 0.08)',
  shadowGrey: 'rgba(148, 157, 176, 0.24)',
  buttonShadowGrey: 'rgba(95, 187, 80, 0.24)',
  mediumGrey: '#9E9A9C',
  grey79: '#797982',
  lightestGrey: '#E4E7E9',
  lightGrey: '#cfcfd3',
  textGrey: '#637381',
  backgroundGrey: '#303642',
  white: '#ffffff',
  red: '#f25656',
  borderGrey: '#919EAB',
  placeholderColor: '#949DB0',
  greyIcon: '#212B36',
  disabledGrey: 'rgba(145, 158, 171, 0.24)',
  disabledText: 'rgba(148, 157, 176, 0.8)',
  lightIconGrey: 'rgba(148, 157, 176, 0.16)',
  disabledButton: '#9C9FA4',
  borderCardGrey: 'rgba(145, 158, 171, 0.32)',
  grey5: '#E5E5E5',
  error: '#FF4842',
  darkRed: '#de242b',
  lightBackgroundGrey: '#F4F6F8',
  warning: '#FFC107',
  chartGrey: 'rgba(145, 158, 171, 0.16)',
  lighterBlue: '#6d9ee3',
  mainBlue: '#0C53B7',
  darkBlue: '#3F62F1',
  darkError: '#B72136',
  transparentRed: 'rgba(255, 72, 66, 0.16)',
  warningDark: '#B78103',
  warningTransparent: 'rgba(255, 193, 7, 0.16)',
  blueTransparent: 'rgba(24, 144, 255, 0.16)',
  lightBlueTransparent: 'rgba(116, 202, 255, 0.16)',
  darkestGreen: '#3E7B34',
  greenTransparent: 'rgba(95, 187, 80, 0.16)',
  orangeTransparent: 'rgba(255, 111, 7, 0.16)',
  blueLink: '#1890FF',
  grey800: '#DFE3E8',
  warningLighter: '#FFF7CD',
  greyE: '#E5E8EB',
  greyDE: '#DCE0E8',
  mainOrange: '#FF6F07',
  mainPink: '#6C0A53',
  backgroundPink: '#FDD8D6',
  mainGrape: '#390B67',
  backgroundGrape: '#FDD8F9',
  backgroundBlue: '#D0F2FF',
  blueText: '#04297A',
  greenText: '#295022',
  lighterGreen: '#DCFFD7',
  yelowText: '#7A4F01',
  lighterYellow: '#FFF7CD',
  violetText: '#1D0E74',
  lighterViolet: '#E7DBFE',
  indigoText: '#0C1773',
  lighterIndigo: '#D8E3FE',
  cyanText: '#023762',
  lighterCyan: '#CDFCF3',
  tealText: '#034B53',
  lighterTeal: '#CDFADC',
  limeText: '#295F04',
  lighterLime: '#F0FCCF',
  orangeText: '#7A1801',
  lighterOrange: '#FFEDCD',
  collapsBackgroundGrey: '#F9F9F9',
  tune: '#754BF2',
  bcTune: 'rgba(117, 75, 242, 0.16)',
  lightBlue: '#0FB5CE',
  lightestBlue: 'rgba(15, 181, 206, 0.16)',
  lightGreen: '#7CC615',
  lightestGreen: 'rgba(124, 198, 21, 0.16)',
  greyMarker: '#4D5564',
};
