import { createActions } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------

export const SET_STATUS_BAR = 'SET_STATUS_BAR';

// ------------------------------------
// Synchronous Action Creators
// ------------------------------------

export const {
  setStatusBar,
} = createActions(
  SET_STATUS_BAR,
);
