import { call, put, takeEvery } from 'redux-saga/effects';
import { getAuthInfo } from 'api-client/auth';
import { getCurrentTenantAccount } from 'api-client/account';
import {
  GET_USER_INFO_REQUEST,
  getUserInfoSuccess,
  getUserInfoFailure,
  setSession,
  setIsLoginFormOpen,
  GET_CURRENT_TENANT_ACCOUNT_REQUEST,
  getCurrentTenantAccountFailure,
  getCurrentTenantAccountSuccess,
} from './auth.actions';

// ------------------------------------
// Effects
// ------------------------------------

function* loadUserInfo() {
  try {
    const { data } = yield call(getAuthInfo);
    yield put(getUserInfoSuccess(data));
    yield put(setSession(true));
    yield put(setIsLoginFormOpen(false));
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.statusText === 'Unauthorized') {
      yield put(setSession(false));
      yield put(setIsLoginFormOpen(true));
    }
    yield put(getUserInfoFailure());
  }
}

function* loadTenantAccount(userTenant) {
  try {
    const { data } = yield call(getCurrentTenantAccount, userTenant.payload);
    yield put(getCurrentTenantAccountSuccess(data));
  } catch (error) {
    yield put(getCurrentTenantAccountFailure());
  }
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* authApi() {
  yield takeEvery(GET_USER_INFO_REQUEST, loadUserInfo);
  yield takeEvery(GET_CURRENT_TENANT_ACCOUNT_REQUEST, loadTenantAccount);
}

export default authApi;
