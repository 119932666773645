export const ApplicationImage = {
  // Backup
  'Cloud Apps / OneDrive': '/v1/files/public/icons/onedrive.png',
  'Cloud Apps / MsTeams': '/v1/files/public/icons/teams.png',
  'Cloud Apps': '/v1/files/public/icons/cloud_circle.png',
  'SharePoint Server': '/v1/files/public/icons/sharepoint.png',
  'Exchange Mailbox': '/v1/files/public/icons/outlook.png',
  'Linux File System': '/v1/files/public/icons/drive.png',
  'Virtual Server': '/v1/files/public/icons/cloud_circle.png',
  FileSystem: '/v1/files/public/icons/drive.png',

  // Training
  training: '/v1/files/public/icons/menu_book.png',
};
