import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TileDropdown } from '@zaveit/uikit';
import { lighten } from '@material-ui/core';
import dayjs from 'dayjs';

import 'styles/fonts.scss';
import { AccountState, AuthState, RootState } from 'redux/types';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { getData, saveData } from 'helpers/localStorageHelper';
import { formatTitle, formatValue, resetTitleFormatting } from 'helpers/formatHelper';
import { getConsumptionData, getConsumptionInfo } from 'api-client/consumption';

import useStyles from './Tiles.styles';

function Tiles() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [allProperties, setAllProperties] = useState({});
  const [selectedProperties, setSelectedProperties] = useState(['', '', '', '']);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userInfo } = useSelector((state: RootState): AuthState => state.auth);
  const { selectedAccountView } = useSelector((state: RootState): AccountState => state.account);

  const handleChangeProperty = (e, index) => {
    const value = resetTitleFormatting(e.target.value);
    setSelectedProperties((prev) => {
      const newProps = prev.map((val, idx) => (idx === index ? value : val));
      saveData('dashboardApp_consumption_properties', newProps);
      return newProps;
    });
  };

  const handleSetSelectedProperties = ({ currProperties, fields }) => {
    const formattedProperties = currProperties;
    const propertiesNames = Object.keys(fields) || [];

    for (let i = 0; i < 4; i++) {
      if (formattedProperties[i]) {
        return;
      }
      formattedProperties[i] =
        propertiesNames.find((name) => !formattedProperties.includes(name)) || '';
    }
    setSelectedProperties(formattedProperties);
  };

  const handleGetTilesData = useCallback(
    async ({ tenant }) => {
      try {
        const consumptionTilesData = await getConsumptionData({
          tenant,
          startDate: dayjs().utc().startOf('month').toISOString(),
          endDate: dayjs().utc().toISOString(),
          granularity: 'month',
        });

        setData(consumptionTilesData?.data?.data?.[0]);
      } catch (error) {
        dispatch(
          setStatusBar({
            type: 'error',
            message: error.response?.data?.message || 'Something went wrong! Please try again!',
            open: true,
          }),
        );
      }
      setIsLoading(false);
    },
    [dispatch],
  );

  const handleGetConsumptionData = useCallback(
    async ({ tenant, currProperties }) => {
      setIsLoading(true);
      try {
        const consumptionData = await getConsumptionInfo();

        setAllProperties(consumptionData?.fields);
        handleSetSelectedProperties({ currProperties, fields: consumptionData?.fields });
        handleGetTilesData({ tenant });
      } catch (error) {
        dispatch(
          setStatusBar({
            type: 'error',
            message: error.response?.data?.message || 'Something went wrong! Please try again!',
            open: true,
          }),
        );
      }
    },
    [dispatch, handleGetTilesData],
  );

  useEffect(() => {
    if (userInfo?.tenant) {
      const storedProperties = getData('dashboardApp_consumption_properties') || ['', '', '', ''];
      const tenant = selectedAccountView?.tenant || userInfo.tenant;

      setSelectedProperties(storedProperties);
      handleGetConsumptionData({ tenant, currProperties: storedProperties });
    }
  }, [userInfo.tenant, selectedAccountView, handleGetConsumptionData]);

  return (
    <div className={classes.container}>
      {selectedProperties?.map((selectedProperty, idx) => {
        const propertyConfig: any =
          Object.entries(allProperties)?.find(([key]) => key === selectedProperty)?.[1] || {};
        const { labelName } = propertyConfig;
        const title = typeof propertyConfig === 'string' ? propertyConfig : labelName;

        return (
          <TileDropdown
            title={title || formatTitle(selectedProperty)}
            description="Сurrent month"
            value={formatValue(propertyConfig.data_type, data?.[selectedProperty])}
            icon={propertyConfig.icon}
            color={propertyConfig.iconColor || propertyConfig.color}
            bgColor={
              (propertyConfig.iconColor || propertyConfig.color) &&
              lighten(propertyConfig.iconColor || propertyConfig.color, 0.84)
            }
            options={Object.keys(allProperties)?.map((property) => formatTitle(property))}
            onChange={(e) => handleChangeProperty(e, idx)}
            key={idx}
            loading={isLoading}
          />
        );
      })}
    </div>
  );
}

export default Tiles;
