import { makeStyles } from '@material-ui/core';
import { Colors } from '@zaveit/uikit';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '40px 0 40px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  middleSection: {
    padding: '0 0 40px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  taskContainer: {
    borderRadius: '16px',
    boxShadow:
      '0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
  },
  textSecondaryColor: {
    color: Colors.text_secondary,
  },
  chip: {
    backgroundColor: '#949DB029',
    color: '#687285',
    padding: '2px 8px 2px 8px',
    borderRadius: '6px',
    lineHeight: '20px',
    fontFamily: 'Lato-Bold',
    fontSize: '12px',
  },
  taskItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    borderBottom: `solid 0.5px ${Colors.divider}`,
    cursor: 'pointer',
    '&:hover': { backgroundColor: Colors.hover },
  },
  firstTaskItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    borderBottom: `solid 0.5px ${Colors.divider}`,
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: Colors.hover },
  },
  lastTaskItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: Colors.hover },
  },
  skeletonContainer: {
    borderRadius: '16px',
    padding: '24px',
    marginBottom: '24px',
    boxShadow:
      '0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
  },
}));

export default useStyles;
