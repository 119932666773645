import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@zaveit/icons';
import { ButtonMenu, RiscScore } from '@zaveit/uikit';

import useRedirectToSupport from 'components/hooks/useRedirectToSupport';

import { handleReportFinding, handleAcceptFinding, handleResolveFinding } from './helpers';
import useStyles from './FindingsTable.styles';
import { Statuses } from './constants';

const FindingDetails = ({
  related,
  application,
  description,
  account,
  setFilteredTableData,
  filteredTableData,
  id,
  handleFindingClick,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { redirectToSupport } = useRedirectToSupport();

  const [findings, setFindings] = useState([]);

  useEffect(() => {
    setFindings(related);
  }, [related]);

  const handleUpdateRelatedFinding = (value) => {
    const newRelatedFindings = findings?.filter((item) => item?._id !== value?._id);
    setFindings(newRelatedFindings);
    const newTableData = filteredTableData?.map((item) => {
      if (item?.id === id) {
        return { ...item, related: [...newRelatedFindings] };
      }
      return item;
    });
    setFilteredTableData(newTableData);
  };

  return (
    <div className={classes.collapseContainer}>
      <div className={classes.collapseTitle}>Additional info</div>
      <div className={classes.infoContainer}>
        <div>
          <span className={classes.infoTitle}>Account:</span>
          <span className={classes.infoDescr}>{account}</span>
        </div>
        <div>
          <span className={classes.infoTitle}>Application:</span>
          <span className={classes.infoDescr}>{application}</span>
        </div>
      </div>
      <div className={classes.descrContainer}>
        <span className={classes.infoTitle}>Description:</span>
        <span className={classes.infoDescr}>{description}</span>
      </div>
      {Boolean(findings?.length) && (
        <>
          <div className={classes.collapseTitle}>Related finding</div>
          {findings?.map((relatedFinding) => (
            <div className={classes.relatedFindingInfo}>
              <div
                className={classes.relatedFindingTitleLink}
                role="button"
                aria-hidden="true"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFindingClick(relatedFinding, true, id);
                }}
              >
                {relatedFinding?.title}
              </div>
              <div className={classes.actionContainer}>
                <div className={classes.riscScoreContainer}>
                  <RiscScore score={relatedFinding?.risc_score} />
                </div>
                <ButtonMenu styleClass="transparentButtonIcon" showExpandIcon={false}>
                  <div className={classes.menuIconBtn}>
                    <Icon iconName="more_vert" />
                  </div>
                  {relatedFinding?.status !== Statuses.RESOLVED && (
                    <ButtonMenu.Item
                      onClick={() =>
                        handleResolveFinding({
                          id: relatedFinding?._id,
                          dispatch,
                          isRelated: true,
                          handleSuccess: handleUpdateRelatedFinding,
                        })
                      }
                    >
                      Resolve
                    </ButtonMenu.Item>
                  )}
                  {relatedFinding?.status !== Statuses.ACCEPTED && (
                    <ButtonMenu.Item
                      onClick={() =>
                        handleAcceptFinding({
                          id: relatedFinding?._id,
                          dispatch,
                          isRelated: true,
                          handleSuccess: handleUpdateRelatedFinding,
                        })
                      }
                    >
                      Accept
                    </ButtonMenu.Item>
                  )}
                  {!relatedFinding?.false_positive && (
                    <ButtonMenu.Item
                      style={{ color: 'red' }}
                      onClick={() =>
                        handleReportFinding({
                          id: relatedFinding?._id,
                          dispatch,
                          handleSuccess: redirectToSupport,
                        })
                      }
                    >
                      Report
                    </ButtonMenu.Item>
                  )}
                </ButtonMenu>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default FindingDetails;
