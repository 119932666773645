import { makeStyles } from '@material-ui/core';

import Colors from 'styles/Color.styles';

const useStyles = makeStyles(() => {
  return {
    container: {
      position: 'relative',
      borderRadius: '16px',
      boxShadow:
        '0px 0px 2px rgba(148, 157, 176, 0.24), 0px 16px 32px -4px rgba(148, 157, 176, 0.24)',
      padding: '24px',
      marginBottom: '24px',
      boxSizing: 'border-box',
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flexGrow: 2,
      '& .apexcharts-xaxis-label': {
        fontSize: '14px',
        fontFamily: 'Lato-Normal, sans-serif',
      },
      '& .apexcharts-yaxis-label': {
        fontSize: '14px',
        fontFamily: 'Lato-Normal, sans-serif',
      },
    },
    timelineInfo: {
      display: 'flex',
      gap: '12px',
    },
    timelineHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    labels: { display: 'flex', gap: '24px', alignItems: 'center' },
    chartContainer: {
      width: '100%',
    },
    legend: { display: 'flex', gap: '12px' },
    marker: {
      minWidth: 'max-content',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: Colors.lightIconGrey,
      borderRadius: '50px',
      fontSize: '14px',
      fontFamily: 'Lato-Normal, sans-serif',
      lineHeight: '22px',
      color: Colors.greyMarker,

      '&:hover > div:last-of-type span': {
        opacity: 0.24,
      },
      '& > div:last-of-type span:hover': {
        opacity: 1,
      },
    },
    colorMarker: {
      margin: '9px 10px',
      width: '6px',
      height: '6px',
      backgroundColor: ({ color }: any) => color,
      borderRadius: '50%',
    },
    deleteIcon: {
      margin: '4px 6px',
      fontSize: '15px',
      color: Colors.subtitleGrey,
      cursor: 'pointer',
      opacity: 0,

      '&:hover': {
        color: Colors.backgroundGrey,
      },
    },
  };
});

export default useStyles;
