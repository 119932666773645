import axios from 'axios';

import Config from '../config/index';

const instance = axios.create({ baseURL: Config.baseConsumptionUrl, withCredentials: true });

export const getConsumptionInfo = async () => {
  try {
    const { data } = await instance.get('/v1/consumption/index');
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getConsumptionData = ({ tenant, startDate, endDate, granularity = 'week' }) => {
  try {
    return instance.get('/v1/consumption', {
      params: {
        'filter[tenant]': tenant,
        startDate,
        endDate,
        granularity,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getProperty = ({ startDate, endDate, tenant = undefined }) => {
  return instance.get('/v2/consumption', {
    params: {
      startDate,
      endDate,
      'filter[tenant]': tenant,
    },
  });
};

export const getConsumptionProperties = async (tenant = undefined) => {
  try {
    const { data } = await instance.get('/v1/consumption/properties/details', {
      params: {
        'filter[tenant]': tenant,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
