import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import rootSaga from './root.sagas';

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(rootReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware),
  ));

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
