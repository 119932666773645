import axios from 'axios';
import Config from 'config/index';

const backupInstance = axios.create({ baseURL: Config.baseBackupUrl, withCredentials: true });
const vsaasInstance = axios.create({ baseURL: Config.baseVsaasUrl, withCredentials: true });

export const getFindings = async () => {
  try {
    const response = await vsaasInstance.get('/v1/vsaas/definition');
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getBackupTargets = async () => {
  try {
    const { data } = await backupInstance.get('/v1/backup/target?expand=account');
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getVsaasTargets = async () => {
  try {
    const { data } = await vsaasInstance.get('/v1/vsaas/target?expand=account');
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};
