import { createActions } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------
export const GET_FINDINGS_REQUEST = 'GET_FINDINGS_REQUEST';
export const GET_FINDINGS_SUCCESS = 'GET_FINDINGS_SUCCESS';
export const GET_FINDINGS_FAILURE = 'GET_FINDINGS_FAILURE';

// ------------------------------------
// Synchronous Action Creators
// ------------------------------------

export const {
  getFindingsRequest,
  getFindingsSuccess,
  getFindingsFailure,
} = createActions(
  GET_FINDINGS_REQUEST,
  GET_FINDINGS_SUCCESS,
  GET_FINDINGS_FAILURE,
);
