import { makeStyles } from '@material-ui/core';
import 'styles/fonts.scss';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '16px',
    margin: '20px auto',
    '& iframe': {
      borderRadius: '16px',
    },
    '& video': {
      borderRadius: '16px',
    },
  },
}));

export default useStyles;
