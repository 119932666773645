export const DataPaths = {
  name: 'name',
  application: 'application',
  severity: 'severity',
  status: 'status',
  discovered: 'discovered',
  updated: 'updated',
  RISC: 'RISC',
};

export const filters = {
  0: 'open',
  1: 'resolved',
  2: 'accepted',
  3: 'false_positive',
  4: 'all',
};

export const dashboardTabs = ['open', 'resolved', 'accepted', 'false_positive', 'all'];

export const initialFiltersData = {
  title: '',
  RISC: '',
  severity: '',
  application: '',
  discovered: '',
  updated: '',
};

export enum Tabs {
  OPEN = 0,
  RESOLVED = 1,
  ACCEPTED = 2,
  FALSE_POSITIVE = 3,
  ALL = 4,
}

export enum Statuses {
  OPEN = 'open',
  RESOLVED = 'resolved',
  ACCEPTED = 'accepted',
}
