import React, { useCallback } from 'react';
import { DataGrid, useServerPagination } from '@zaveit/uikit';
import isEqual from 'lodash.isequal';

import { useCustomerTableConfig } from 'hooks/tables/useCustomerTableConfig';

import useStyles from './CustomerTable.styles';

import { tableNames, rowsPerPageOptions, defaultSortModels } from '../constants';

const CustomerTable = ({ customer, handleGetData, customersLoading, tenant }) => {
  const classes = useStyles();
  const { headCellsCustomer, headCellsProduct, headCellsProductItems } = useCustomerTableConfig();
  const { tableConfig, handleChangePage, handleChangeRowsPerPage, handleSortingModelChange } =
    useServerPagination({
      defaultConfig: {
        offset: 0,
        limit: rowsPerPageOptions[0],
        sortModel: defaultSortModels.customer,
      },
      tableName: tableNames.customer,
      handleGetData,
      tenant,
    });

  const getDetailPanelHeight = useCallback(() => 'auto' as 'auto', []);

  const getDetailPanelContentItems = useCallback(
    (value) => {
      const rows = value?.row?.items;

      return (
        rows?.length > 0 && (
          <div>
            <DataGrid
              name="dashboardApp_customer_product_items"
              columns={headCellsProductItems}
              rows={rows}
              emptyViewConfig={{
                iconName: 'list_alt',
                title: 'No data',
              }}
              hasBorder
              minHeight="60"
              headerHeight={40}
              hasHeader={false}
              hasShadow={false}
              getDetailPanelContent={getDetailPanelContentItems}
              getDetailPanelHeight={getDetailPanelHeight}
              sectionPadding="8px"
              pagination={false}
              getRowId={(inputRow) => inputRow._id}
            />
          </div>
        )
      );
    },
    [headCellsProductItems],
  );

  const getDetailPanelContent = useCallback(
    (value) => {
      const rows = value?.row?.subscriptions;
      return (
        rows?.length > 0 && (
          <div className={classes.innerTableContainer}>
            <DataGrid
              name="dashboardApp_customer_product"
              columns={headCellsProduct}
              headerHeight={1}
              rows={rows}
              emptyViewConfig={{
                iconName: 'list_alt',
                title: 'No data',
              }}
              minHeight="60"
              hasHeader={false}
              hasShadow={false}
              getDetailPanelContent={getDetailPanelContentItems}
              getDetailPanelHeight={getDetailPanelHeight}
              sectionPadding="0"
              pagination={false}
              getRowId={(inputRow) => inputRow._id}
            />
          </div>
        )
      );
    },
    [classes.innerTableContainer, getDetailPanelContentItems, headCellsProduct],
  );

  return (
    <DataGrid
      name={tableNames.customer}
      columns={headCellsCustomer}
      rows={customer?.data || []}
      initialState={{
        sortingField: tableConfig?.sortModel?.field,
        sortingOrder: tableConfig?.sortModel?.sort,
      }}
      emptyViewConfig={{
        iconName: 'list_alt',
        title: 'No data',
      }}
      getRowId={(row) => row._id}
      hideExport
      hideSearch
      loading={customersLoading}
      sortingMode="server"
      handleSortingModelChange={handleSortingModelChange}
      serverSortModel={tableConfig?.sortModel}
      paginationMode="server"
      page={tableConfig?.offset / tableConfig?.limit || 0}
      onPageChange={handleChangePage}
      rowCount={customer?.total?.total || 0}
      rowsPerPage={tableConfig?.limit}
      rowsPerPageOptions={rowsPerPageOptions}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      hasHeader={false}
      hasShadow={false}
      sectionPadding="0 8px"
    />
  );
};

export default React.memo(CustomerTable, isEqual);
