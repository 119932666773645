import { Button, Tooltip } from '@zaveit/uikit';
import { Icon } from '@zaveit/icons';

import useStyles from './Dashboard.styles';

function CourseCard({ item }) {
  const classes = useStyles({});
  const description =
    item?.description?.length > 100
      ? `${item?.description.substring(0, 100)}...`
      : item?.description;

  const handleStartCourse = () => {
    window.location.replace(`https://${window.location.host}/training/courses/${item?.id}`);
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.courseTitleContainer}>
        <div className={classes.iconCourseContainer}>
          <Icon iconName="menu_book" />
        </div>
        <div>
          <span className={classes.courseTitle}>{item?.title}</span>
          <div className={classes.courseSubTitle}>{item?.category}</div>
        </div>
      </div>
      <Tooltip title={item?.description}>
        <div className={classes.courseDescr}>{description}</div>
      </Tooltip>
      <div className={classes.courseBtnContainer}>
        {item?.status?.active ? (
          <Button
            styleClass="activeYellow"
            width="fit-content"
            nextButton
            height="30px"
            onClick={handleStartCourse}
          >
            Resume course
          </Button>
        ) : (
          <Button
            styleClass="activeGreen"
            width="fit-content"
            nextButton
            height="30px"
            onClick={handleStartCourse}
          >
            Start course
          </Button>
        )}
      </div>
      <div className={classes.imageContainer}>
        <Icon iconName="image" />
      </div>
    </div>
  );
}

export default CourseCard;
