import {
  RiscScore,
  FindingStatusLabel,
  RiscLevel,
  DataGridAction,
  DataGridColumns,
} from '@zaveit/uikit';
import { useDispatch } from 'react-redux';
import format from '@zaveit/format';
import { Statuses } from './constants';
import useStyles from './FindingsTable.styles';

export const useTableConfig = ({
  handleResolveFinding,
  handleGetStatistics,
  tab,
  handleAcceptFinding,
  handleReportFinding,
  handleReport,
}): { headCells: DataGridColumns } => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const headCells: DataGridColumns = [
    {
      field: 'id',
      headerName: '',
      minWidth: 0,
      maxWidth: 0,
      width: 0,
      renderHeader: () => {
        return <div className={classes.hiddenHeaderCell}>id</div>;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 260,
      flex: 1,
      valueGetter: (params) => params?.value || <>&mdash;</>,
    },
    {
      field: 'application',
      headerName: 'Application',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <>{value ? <span>{value}</span> : <>&mdash;</>}</>,
    },
    {
      field: 'severity',
      headerName: 'Severity',
      minWidth: 180,
      flex: 1,
      valueGetter: (params) => params?.value || <>&mdash;</>,
      renderCell: ({ value }) => <>{value ? <RiscLevel value={value} /> : <>&mdash;</>}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 160,
      flex: 0.5,
      valueGetter: (params) => params?.value || '',
      renderCell: ({ value }) => <FindingStatusLabel value={value} />,
    },
    {
      field: 'discovered',
      headerName: 'Discovered',
      type: 'dateTime',

      minWidth: 100,
      flex: 0.5,
      valueGetter: (params) => params?.value || '',
      renderCell: (params) => (params.value ? format.date(params.value).long : <>&mdash;</>),
    },
    {
      field: 'updated',
      headerName: 'Updated',
      type: 'dateTime',
      minWidth: 100,
      flex: 0.5,
      valueGetter: (params) => params?.value || '',
      renderCell: (params) => (params?.value ? format.date(params?.value).long : <>&mdash;</>),
    },
    {
      field: 'RISC',
      headerName: 'RISC',
      minWidth: 100,
      flex: 0.5,
      type: 'number',
      valueGetter: (params) => params?.value || 0,
      renderCell: ({ value }) => <RiscScore score={value} />,
    },
    {
      field: 'actions',
      width: 56,
      type: 'actions',
      resizable: false,
      getActions: ({ row }) => {
        let actions = [];
        if (row?.status !== Statuses.RESOLVED) {
          actions = [
            ...actions,
            DataGridAction({
              onClick: () =>
                handleResolveFinding({
                  id: row?.id,
                  dispatch,
                  handleSuccess: handleGetStatistics,
                  tab,
                }),
              label: 'Resolve',
            }),
          ];
        }

        if (row?.status !== Statuses.ACCEPTED) {
          actions = [
            ...actions,
            DataGridAction({
              onClick: () =>
                handleAcceptFinding({
                  id: row?.id,
                  dispatch,
                  handleSuccess: handleGetStatistics,
                  tab,
                }),
              label: 'Accept',
            }),
          ];
        }

        if (!row?.false_positive) {
          actions = [
            ...actions,
            DataGridAction({
              onClick: () =>
                handleReportFinding({
                  id: row?.id,
                  dispatch,
                  handleSuccess: handleReport,
                  tab,
                }),
              label: 'Report',
            }),
          ];
        }
        return actions;
      },
    },
  ];

  return { headCells };
};
