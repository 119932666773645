import { call, put, takeEvery } from 'redux-saga/effects';
import { getFindings } from 'api-client/target';
import { setSession } from 'redux/auth/auth.actions';
import {
  GET_FINDINGS_REQUEST,
  getFindingsFailure,
  getFindingsSuccess,
} from './targets.actions';

// ------------------------------------
// Effects
// ------------------------------------

function* loadFindings() {
  try {
    const { data } = yield call(getFindings);
    yield put(getFindingsSuccess(data));
    yield put(setSession(true));
  } catch (error) {
    if (error.response?.status === 401 || error.response?.statusText === 'Unauthorized') {
      yield put(setSession(false));
    }
    yield put(getFindingsFailure());
  }
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* targetsApi() {
  yield takeEvery(GET_FINDINGS_REQUEST, loadFindings);
}

export default targetsApi;
