import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      gap: '24px',
      marginBottom: '24px',
      '& > div': {
        width: '100%',
      },
    },
  };
});

export default useStyles;
