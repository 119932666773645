import { Autocomplete } from '@zaveit/uikit';
import dayjs from 'dayjs';
import { Icon } from '@zaveit/icons';

import useStyles from './FindingsTable.styles';

const Filters = ({ filters, setFilters, handleClearAll, tableData }) => {
  const classes = useStyles();

  const handleGetOptions = (field) => {
    let values = [];
    if (field === 'updated' || field === 'discovered') {
      values = tableData.map((item) => dayjs(item?.[field]).format('DD.MM.YYYY'));
    } else {
      values = tableData.map((item) => item?.[field]);
    }
    values = values?.filter((item) => item);
    const set = [...new Set(values)];
    const newItems = set.map((item: any) => ({ param: item, value: item }));
    return newItems.sort((a, b) => {
      if (typeof a?.value === 'string') {
        return a.value?.localeCompare(b.value);
      }
      return a.value - b.value;
    });
  };

  const filtersConfig = [
    {
      name: 'name',
      label: 'Title',
      options: handleGetOptions('name'),
    },
    {
      name: 'RISC',
      label: 'RISC',
      options: [
        { param: [0, 2], value: '0 - 2' },
        { param: [2, 4], value: '2 - 4' },
        { param: [4, 6], value: '4 - 6' },
        { param: [6, 8], value: '6 - 8' },
        { param: [8, 9], value: '8 - 10' },
      ],
    },
    {
      name: 'severity',
      label: 'Severity',
      options: [
        { param: 'low', value: 'Low' },
        { param: 'medium', value: 'Medium' },
        { param: 'high', value: 'High' },
        { param: 'critical', value: 'Critical' },
      ],
    },
    {
      name: 'application',
      label: 'Application',
      options: handleGetOptions('application'),
    },
    {
      name: 'discovered',
      label: 'Discovered',
      options: handleGetOptions('discovered'),
    },
    {
      name: 'updated',
      label: 'Updated',
      options: handleGetOptions('updated'),
    },
    {
      name: 'account',
      label: 'Account',
      options: handleGetOptions('account'),
    },
  ];

  const isFilterData = Object.values(filters).some((value) => value);

  return (
    <div className={classes.filtersContainer}>
      {filtersConfig.map((filter) => {
        return (
          <div className={classes.selectContainer} key={filter.name}>
            <Autocomplete
              handleSetValue={(value) => {
                setFilters((prevState) => ({ ...prevState, [filter.name]: value }));
              }}
              height="40px"
              currentValue={filters[filter.name] || null}
              getSelectedOption={(option, value) => option?.value === value?.value}
              options={filter?.options || []}
              label={filter?.label}
              getOptionLabel={(option) => option?.value}
              labelOffset="-6px"
            />
          </div>
        );
      })}
      {isFilterData && (
        <button type="button" onClick={handleClearAll} className={classes.clearBtn}>
          <Icon iconName="clear_all" className={classes.clearIcon} />
          <span>Clear all</span>
        </button>
      )}
    </div>
  );
};

export default Filters;
