import { makeStyles } from '@material-ui/core';

import Colors from 'styles/Color.styles';

const useStyles = makeStyles(() => ({
  innerTableContainer: {
    border: `1px solid ${Colors.shadowGrey}`,
    borderRadius: '16px',
    padding: '8px',
  },
  accountLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  accountLabel: {
    backgroundColor: Colors.blueTransparent,
    borderRadius: '6px',
    color: Colors.mainLightBlue,
    fontSize: '12px',
    lineHeight: '12px',
    fontFamily: 'Lato-Bold, sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px',
    height: '22px',
    width: 'fit-content',
  },
}));

export default useStyles;
