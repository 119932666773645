import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Snackbar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { RootState, StatusBarState } from 'redux/types';
import useStyles from './StatusBar.styles';

function StatusBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { type, open, message } = useSelector(
    (state: RootState): StatusBarState => state.statusbar,
  );
  const handleClose = () => {
    dispatch(setStatusBar({ type: '', open: false, message: '' }));
  };
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      classes={{ root: classes.snackbarSuccess }}
    >
      <div className={classes.snackContent}>
        {type === 'success' && (
          <div className={classes.iconContainer}>
            <CheckCircleIcon className={classes.checkIcon} />
          </div>
        )}
        {type === 'error' && (
          <div className={classes.iconErrorContainer}>
            <ErrorIcon className={classes.errorIcon} />
          </div>
        )}
        <div>{message}</div>
      </div>
    </Snackbar>
  );
}

export default StatusBar;
