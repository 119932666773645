import axios from 'axios';

import Config from 'config/index';

const instance = axios.create({ baseURL: Config.baseAccountUrl, withCredentials: true });

export const getCurrentTenantAccount = (id: string) => {
  try {
    return instance.get(`/v1/account?filter[tenant]=${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const getExtendedAccount = async (tenant) => {
  try {
    const { data } = await instance.get(
      `/v1/account?extended=users,orders&filter[tenant]=!${tenant}`,
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getCustomerSeries = async ({ days }: { days: number }) => {
  try {
    const { data } = await instance.get('/v1/account/history', {
      params: {
        days,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAccounts = async ({
  tenant = null,
  isExtended = false,
  pagination = false,
  limit = null,
  offset = 0,
  sortModel = undefined,
}: {
  tenant: number;
  isExtended?: boolean;
  pagination?: boolean;
  limit?: number;
  offset?: number;
  sortModel?: any;
}) => {
  try {
    const sortOrder = sortModel?.sort === 'asc' ? '' : '-';
    return await instance.get('/v1/account', {
      params: {
        'filter[tenant]': tenant && `!${tenant}`,
        extended: isExtended && 'users,orders,subscriptions',
        pagination,
        limit,
        offset,
        sort: sortModel && `${sortOrder}${sortModel?.field}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
