import { TFinding } from 'components/types';
import { handleActions } from 'redux-actions';
import _ from 'lodash';
import {
  GET_FINDINGS_DATA_SUCCESS,
  GET_FINDINGS_DATA_FAILURE,
  GET_FINDINGS_DATA_REQUEST,
  UPDATE_TABLE_FINDING,
  CLEAR_FINDINGS_DATA,
} from './findings.actions';

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  findingsTableData: [],
  findings: [],
  loading: false,
};

export default handleActions(
  {
    [GET_FINDINGS_DATA_REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_FINDINGS_DATA_SUCCESS]: (state, { payload }) => {
      const data = payload?.map((item: TFinding) => {
        return {
          id: item._id,
          name: item?.title,
          application: item?.metadata?.application,
          severity: item?.severity,
          status: item?.status,
          discovered: item?.created,
          updated: item?.updated,
          RISC: item?.risc_score,
          description: item?.description,
          related: item?.metadata?.related,
          tenant: item?.metadata?.tenant,
          account: item?.tenant_name,
          false_positive: item?.false_positive,
        };
      });
      return {
        ...state,
        findingsTableData: data,
        findings: payload,
        loading: false,
      };
    },
    [GET_FINDINGS_DATA_FAILURE]: (state) => ({
      ...state,
      findingsTableData: [],
      findings: [],
      loading: false,
    }),
    [UPDATE_TABLE_FINDING]: (state, { payload }) => {
      const { id, status, shouldFilter = true, ids } = payload;
      let newTableFindingsTableData = [];
      if (shouldFilter) {
        if (ids) {
          newTableFindingsTableData =
            _.filter(state.findingsTableData, (item) => {
              return !ids.includes(item?.id);
            });
        } else {
          newTableFindingsTableData = _.filter(state.findingsTableData, (item) => item?.id !== id);
        }
        return {
          ...state,
          findingsTableData: newTableFindingsTableData,
        };
      }
      if (ids) {
        newTableFindingsTableData = _.map(state.findingsTableData, (item) =>
          ids.includes(item?.id) ? { ...item, status } : item);
      } else {
        newTableFindingsTableData = _.map(state.findingsTableData, (item) =>
          item?.id === id ? { ...item, status } : item);
      }

      return {
        ...state,
        findingsTableData: newTableFindingsTableData,
      };
    },
    [CLEAR_FINDINGS_DATA]: (state) => ({
      ...state,
      findingsTableData: [],
      findings: [],
    }),
  },
  initialState,
);
