import { makeStyles } from '@material-ui/core';

import Colors from 'styles/Color.styles';

const useStyles = makeStyles(() => ({
  container: {
    margin: 'auto',
    width: 'fit-content',

    '& .MuiTypography-body1': {
      width: '33px',
      textAlign: 'center',
      borderRadius: '6px',
      fontFamily: 'Lato-Bold, sans-serif',
      fontSize: '12px',
      lineHeight: '20px',
    },
  },
  green: { color: Colors.darkestGreen, backgroundColor: Colors.greenTransparent },
  yellow: { color: Colors.warningDark, backgroundColor: Colors.warningTransparent },
  red: { color: Colors.darkError, backgroundColor: Colors.transparentRed },
}));

export default useStyles;
