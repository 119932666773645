import axios from 'axios';

import Config from '../config/index';

const instance = axios.create({ baseURL: Config.baseOrderUrl, withCredentials: true });

export const getSubscriptions = ({ tenant = undefined, limit, offset, sortModel = null }) => {
  const isAsc = sortModel?.sort === 'asc';
  const sortingParams = isAsc ? sortModel?.field : `-${sortModel?.field}`;
  const sort = sortModel ? sortingParams : null;
  return instance.get('/v1/order/subscription', {
    params: {
      limit,
      offset,
      'filter[tenant]': tenant,
      populate: 'order_details',
      sort,
    },
  });
};

export const getOrderHistory = async ({ days }: { days: number }) => {
  try {
    const { data } = await instance.get('/v1/order/history', {
      params: {
        days,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderByStatus = async ({ tenant, status }: { tenant: number; status: string }) => {
  try {
    const { data } = await instance.get('/v1/order?', {
      params: {
        'filter[tenant]': `!${tenant}`,
        'filter[status]': status,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
