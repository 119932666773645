const standardView = [
  'owner.user',
  'provider.user',
  'consumer.user',
  'owner.helpdesk_operator',
  'provider.helpdesk_operator',
  'owner.helpdesk_operator_own',
  'provider.helpdesk_operator_own',
];

const consumerAdminDashboardRoles = ['consumer.admin'];

const providerAdminView = ['owner.admin', 'provider.admin'];

const accountManagerDashboardRoles = [
  'owner.product_manager',
  'provider.product_manager',
  'owner.sales_manager',
  'provider.sales_manager',
  'owner.account_manager',
  'provider.account_manager',
];

export enum HomeViews {
  STANDARD_VIEW = 'standard_view',
  CONSUMER_ADMIN_VIEW = 'consumer_admin_view',
  PROVIDER_ADMIN_VIEW = 'provider_admin_view',
  ACCOUNT_MANAGER_VIEW = 'account_manager_view',
}

export const handleGetHomeView = ({ role }) => {
  let homeView;

  switch (true) {
    case standardView.includes(role):
      homeView = HomeViews.STANDARD_VIEW;
      break;
    case consumerAdminDashboardRoles.includes(role):
      homeView = HomeViews.CONSUMER_ADMIN_VIEW;
      break;
    case providerAdminView.includes(role):
      homeView = HomeViews.PROVIDER_ADMIN_VIEW;
      break;
    case accountManagerDashboardRoles.includes(role):
      homeView = HomeViews.ACCOUNT_MANAGER_VIEW;
      break;
    default:
      homeView = HomeViews.STANDARD_VIEW;
      break;
  }

  return homeView;
};
