import { makeStyles } from '@material-ui/core';
import Colors from 'styles/Color.styles';
import 'styles/fonts.scss';

const useStyles = makeStyles(() => ({
  snackbarSuccess: {
    backgroundColor: Colors.white,
    color: Colors.backgroundGrey,
    padding: '12px',
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(148, 157, 176, 0.24)',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Lato-Normal, sans-serif',
    maxWidth: '300px',
  },
  snackContent: {
    display: 'flex',
    alignItems: 'center',
  },
  iconErrorContainer: {
    backgroundColor: 'rgba(255, 72, 66, 0.16)',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '40px',
    height: '40px',
    marginRight: '12px',
  },
  iconContainer: {
    backgroundColor: 'rgba(95, 187, 80, 0.16)',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '40px',
    height: '40px',
    marginRight: '12px',
  },
  checkIcon: {
    color: Colors.mainGreen,
  },
  errorIcon: {
    color: Colors.error,
  },
}));

export default useStyles;
