import dayjs from 'dayjs';

import { TimeUnitsShortNames } from 'components/constants';

export const getTimeDifference = (date) => {
  const currentDate = dayjs();
  const itemDate = dayjs(date);

  let time = currentDate.diff(itemDate, 'w');
  let unit = TimeUnitsShortNames.week;

  if (time < 1) {
    time = currentDate.diff(itemDate, 'd');
    unit = TimeUnitsShortNames.day;
  }
  if (time < 1) {
    time = currentDate.diff(itemDate, 'h');
    unit = TimeUnitsShortNames.hour;
  }
  if (time < 1) {
    time = currentDate.diff(itemDate, 'm');
    unit = TimeUnitsShortNames.minute;
  }

  return `${time}${unit}`;
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
