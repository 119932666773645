import { useEffect, useState, useCallback } from 'react';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import format from '@zaveit/format';
import { DataGrid, FindingDetailsModal, useDefaultPagination } from '@zaveit/uikit';
import { Tabs, Tab } from '@material-ui/core';
import useRedirectToSupport from 'components/hooks/useRedirectToSupport';

import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import { TFinding } from 'components/types';
import { getFindingsStaticstics, getFinding } from 'api-client/risc';
import { clearFindingsData, getFindingsDataRequest } from 'redux/findings/findings.actions';
import { AuthState, FindingsState, RootState } from 'redux/types';

import { getData, saveData } from 'helpers/localStorageHelper';
import { handleServiceClick } from 'components/helpers/findings';
import FindingDetails from './FindingDetail';
import { initialFiltersData, filters, dashboardTabs } from './constants';
import FindingsActions from './FindingsActions';
import Filters from './Filters';
import { handleReportFinding, handleAcceptFinding, handleResolveFinding } from './helpers';
import useStyles from './FindingsTable.styles';
import { useTableConfig } from './useTableConfig';

const FindingsTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { redirectToSupport } = useRedirectToSupport();

  const { userInfo } = useSelector((state: RootState): AuthState => state.auth);
  const { findings, findingsTableData, loading } = useSelector(
    (state: RootState): FindingsState => state.findings,
  );
  const params = new URLSearchParams(window.location.search);
  const search = params.get('s');
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [selectedFinding, setSelectedFinding] = useState<TFinding>(null);
  const [selectedFindingsIds, setSelectedFindingsIds] = useState<any[]>([]);
  const [parentFinding, setParentFinding] = useState<TFinding>(null);
  const [breadcrumbsConfig, setBreadcrumbsConfig] = useState([]);
  const [tab, setTab] = useState(0);
  const [filtersData, setFiltersData] = useState(initialFiltersData);
  const isFilterData = Object.values(filtersData).some((value) => value);
  const [statistic, setStatistic] = useState({
    accepted: 0,
    false_positive: 0,
    open: 0,
    resolved: 0,
    all: 0,
  });

  const defaultBreadcrumbsConfig: any = [
    {
      name: 'Finding',
      tenant: '',
      disabled: true,
    },
    {
      name: parentFinding?.title,
      tenant: parentFinding?.metadata?.tenant,
      item: parentFinding,
    },
  ];

  const defaultPagination = useDefaultPagination({
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    dataLength: filteredTableData?.length,
  });

  const handleChangePage = (newPage: number) => {
    defaultPagination.onChangePage(null, newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    if (value === defaultPagination.rowsPerPage) return;
    const e = { target: { value } };
    defaultPagination.onChangeRowsPerPage(e);
  };

  const pagination = {
    ...defaultPagination,
    lazy: true,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onChangePage: handleChangePage,
  };

  const handleGetFilteredData = useCallback(() => {
    let newData = findingsTableData.slice();
    for (let key in filtersData) {
      if (key === 'RISC' && filtersData[key]) {
        // @ts-expect-error
        const range = filtersData[key]?.param;
        newData = newData.filter((item) => {
          return range[0] <= Number(item[key]) && item[key] < range[1];
        });
      }
      if ((key === 'updated' || key === 'discovered') && filtersData[key]) {
        newData = newData.filter((item) => {
          const keyValue = format.date(item[key]).long;

          return keyValue.split(' ')[0] === filtersData[key]?.param;
        });
        return newData;
      }
      if (filtersData[key] && key !== 'RISC') {
        newData = newData.filter((item) => item[key] === filtersData[key]?.param);
      }
    }
    return newData;
  }, [filtersData, findingsTableData]);

  const getFindingData = (id) => findings.find((item) => item?._id === id);

  const handleRowClick = (id) => {
    setDetailsOpen(true);
    const finding = getFindingData(id);
    const newFinding = filteredTableData.find((item) => item?.id === finding?._id);
    const updatedFinding = {
      ...finding,
      metadata: { ...finding?.metadata, related: [...newFinding?.related] },
    };
    setParentFinding(updatedFinding);
    setSelectedFinding(updatedFinding);
    const config = [
      {
        name: 'Finding',
        tenant: '',
        disabled: true,
      },
      {
        name: updatedFinding?.title,
        tenant: updatedFinding?.metadata?.tenant,
        item: updatedFinding,
      },
    ];
    setBreadcrumbsConfig(config);
  };

  const handleModalClose = () => {
    setDetailsOpen(false);
    setSelectedFinding(null);
    setParentFinding(null);
  };

  const handleUpdateSelectedFinding = (value) => {
    const newRelatedFindings = selectedFinding?.metadata?.related?.filter(
      (item) => item?._id !== value?._id,
    );
    setSelectedFinding((prevState) => ({
      ...prevState,
      metadata: { ...prevState.metadata, related: [...newRelatedFindings] },
    }));

    const newTableData = filteredTableData?.map((item) => {
      if (item?.id === selectedFinding?._id) {
        return { ...item, related: [...newRelatedFindings] };
      }
      return item;
    });
    setFilteredTableData(newTableData);
  };

  const handleBreadcrumbsClick = (value) => {
    setSelectedFinding(value);
    if (value?._id === parentFinding?._id) {
      setBreadcrumbsConfig(defaultBreadcrumbsConfig);
    } else {
      const index = breadcrumbsConfig.findIndex((el) => el._id === value?._id);
      const newConfig = breadcrumbsConfig.slice(0, index);
      setBreadcrumbsConfig(newConfig);
    }
  };

  const handleClickRelatedFinding = (
    value: TFinding,
    isDetailsView = false,
    parentFindingId = null,
  ) => {
    setSelectedFinding(value);
    if (isDetailsView) {
      const finding = getFindingData(parentFindingId);
      setParentFinding(finding);
      setDetailsOpen(true);
      const newConfig = [
        {
          name: 'Finding',
          tenant: '',
          disabled: true,
        },
        {
          name: finding?.title,
          tenant: finding?.metadata?.tenant,
          item: finding,
        },
        {
          name: value?.title,
          tenant: value?.metadata?.tenant,
          item: value,
        },
      ];
      setBreadcrumbsConfig(newConfig);
    } else {
      const newConfig = [
        ...breadcrumbsConfig,
        {
          name: value?.title,
          tenant: value?.metadata?.tenant,
          item: value,
        },
      ];

      setBreadcrumbsConfig(newConfig);
    }
  };

  const handleChangeTab = (event, mode) => {
    dispatch(clearFindingsData());
    handleChangePage(0);
    setTab(mode);
    saveData('tab', dashboardTabs[mode]);
  };

  const handleGetStatistics = async () => {
    try {
      const data = await getFindingsStaticstics(userInfo?.tenant);
      setStatistic(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleReport = (finding) => {
    handleGetStatistics();
    redirectToSupport(finding);
  };

  const { headCells } = useTableConfig({
    handleResolveFinding,
    tab,
    handleGetStatistics,
    handleAcceptFinding,
    handleReport,
    handleReportFinding,
  });

  const handleClearAll = () => {
    setFiltersData(initialFiltersData);
    handleChangePage(0);
    setFilteredTableData(findingsTableData);
  };

  const getDetailPanelContent = useCallback(({ row }) => {
    return (
      row && (
        <FindingDetails
          {...row}
          filteredTableData={filteredTableData}
          handleFindingClick={handleClickRelatedFinding}
          setFilteredTableData={setFilteredTableData}
        />
      )
    );
  }, []);

  const getDetailPanelHeight = useCallback(() => {
    return 'auto' as 'auto';
  }, []);

  const handleGetFinding = async () => {
    try {
      const data = await getFinding(search);
      const newData = {
        id: data._id,
        name: data?.title,
        application: data?.metadata?.application,
        severity: data?.severity,
        status: data?.status,
        discovered: data?.created,
        updated: data?.updated,
        RISC: data?.risc_score,
        description: data?.description,
        related: data?.metadata?.related,
        tenant: data?.metadata?.tenant,
        account: data?.tenant_name,
        false_positive: data?.false_positive,
      };
      setFilteredTableData([newData]);
    } catch (err) {
      setFilteredTableData([]);
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not get finding',
          open: true,
        }),
      );
    }
  };

  useEffect(() => {
    if (search) {
      handleGetFinding();
      return;
    }

    if (isFilterData) {
      setFilteredTableData(handleGetFilteredData());
      return;
    }
    setFilteredTableData(findingsTableData);
  }, [findingsTableData, isFilterData, handleGetFilteredData, search]);

  useEffect(() => {
    if (userInfo?.tenant) {
      handleGetStatistics();
    }
  }, [userInfo?.tenant]);

  useEffect(() => {
    if (userInfo?.tenant) {
      let filter = `&filter[status]=${filters[tab]}`;
      if (filters[tab] === 'false_positive') {
        filter = '&filter[false_positive]=true';
      }
      if (filters[tab] === 'all') {
        filter = '';
      }
      dispatch(
        getFindingsDataRequest({
          tenant: userInfo?.tenant,
          filter: `${filter}`,
        }),
      );
    } else {
      dispatch(clearFindingsData());
    }
  }, [userInfo?.tenant, tab, dispatch]);

  useEffect(() => {
    const savedTab = getData('tab');
    const value = dashboardTabs.indexOf(savedTab) > -1 ? dashboardTabs.indexOf(savedTab) : 0;
    setTab(value);
  }, []);

  return (
    <div>
      <div className={classes.titleContainer}>
        <div className={classes.backContainer}>
          <ArrowBackIosRoundedIcon
            role="button"
            aria-hidden="true"
            onClick={() => {
              history.goBack();
            }}
            className={classes.backIcon}
          />
          <div>Findings</div>
        </div>
      </div>
      <div className={classes.tabsContainer}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            label={`Open (${statistic.open})`}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label={`Resolved (${statistic.resolved})`}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label={`Accepted (${statistic.accepted})`}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label={`False positives (${statistic.false_positive})`}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label={`All (${statistic.all})`}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
        </Tabs>
      </div>
      <Filters
        setFilters={setFiltersData}
        filters={filtersData}
        handleClearAll={handleClearAll}
        tableData={findingsTableData}
      />
      <DataGrid
        name="dashboard_findings"
        columns={headCells}
        rows={filteredTableData}
        {...pagination}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        loading={loading}
        checkboxSelection
        onSelectionModelChange={(value) => setSelectedFindingsIds(value)}
        onRowClick={({ row }) => {
          handleRowClick(row?.id);
        }}
        emptyViewConfig={{
          iconName: 'radar',
          title: isFilterData ? 'No findings found' : 'No findings discovered yet',
          description: isFilterData ? 'There are no findings according to filters' : '',
        }}
        initialState={{
          sortingField: 'time',
          sortingOrder: 'desc',
        }}
        rowSelectionModel={selectedFindingsIds}
      >
        {Boolean(selectedFindingsIds?.length) && (
          <FindingsActions
            ids={selectedFindingsIds}
            tab={tab}
            handleGetStatistics={handleGetStatistics}
            setSelectedFindingsIds={setSelectedFindingsIds}
          />
        )}
      </DataGrid>
      <FindingDetailsModal
        open={detailsOpen}
        handleClose={handleModalClose}
        finding={selectedFinding}
        breadcrumbsConfig={breadcrumbsConfig}
        handleClick={(value) => handleBreadcrumbsClick(value)}
        handleServiceClick={(service) => handleServiceClick({ service, selectedFinding, userInfo })}
        handleResolve={(id) => {
          handleResolveFinding({ id, dispatch, handleSuccess: handleGetStatistics, tab });
          setDetailsOpen(false);
        }}
        handleResolveRelatedFinding={(id) =>
          handleResolveFinding({ id, handleSuccess: handleUpdateSelectedFinding, isRelated: true })
        }
        handleAccept={(id) => {
          handleAcceptFinding({ id, dispatch, handleSuccess: handleGetStatistics, tab });
          setDetailsOpen(false);
        }}
        handleAcceptRelatedFinding={(id) =>
          handleAcceptFinding({ id, handleSuccess: handleUpdateSelectedFinding, isRelated: true })
        }
        handleReport={(id) => {
          handleReportFinding({ id, dispatch, handleSuccess: handleReport });
          setDetailsOpen(false);
        }}
        handleReportRelatedFinding={(id) =>
          handleReportFinding({ id, handleSuccess: handleReport })
        }
        handleClickRelatedFinding={(value: TFinding) => handleClickRelatedFinding(value)}
      />
    </div>
  );
};

FindingsTable.defaultProps = {
  customers: [],
};

export default FindingsTable;
