import { makeStyles } from '@material-ui/core';
import { Colors } from '@zaveit/uikit';

const useStyles = makeStyles((theme) => ({
  taskContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  noTextDecoration: {
    textDecoration: 'none',
  },
  chip: {
    backgroundColor: '#949DB029',
    color: '#687285',
    padding: '2px 8px 2px 8px',
    borderRadius: '6px',
    lineHeight: '20px',
    fontFamily: 'Lato-Bold',
    fontSize: '12px',
  },
  taskItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    borderBottom: `solid 0.5px ${Colors.divider}`,
    cursor: 'pointer',
    '&:hover': { backgroundColor: Colors.hover },
  },
  firstTaskItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    borderBottom: `solid 0.5px ${Colors.divider}`,
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: Colors.hover },
  },
  lastTaskItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: Colors.hover },
  },
  textSecondaryColor: {
    color: Colors.text_secondary,
  },
}));

export default useStyles;
