import { handleActions } from 'redux-actions';
import { StatusBarState } from 'redux/types';
import {
  SET_STATUS_BAR,
} from './statusBar.actions';

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  type: '',
  open: false,
  message: '',
} as StatusBarState;

export default handleActions(
  {
    [SET_STATUS_BAR]: (state, { payload: { type, open, message } }) => {
      return {
        ...state,
        type,
        open,
        message,
      };
    },
  },
  initialState,
);
