import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/auth.reducer';
import targetsReducer from './targets/targets.reducer';
import statusBarReducer from './statusBar/statusBar.reducer';
import findingsReducer from './findings/findings.reducer';
import accountReducer from './account/account.reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  targets: targetsReducer,
  statusbar: statusBarReducer,
  findings: findingsReducer,
  account: accountReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
