import axios from 'axios';
import Config from 'config/index';

const instance = axios.create({ baseURL: Config.baseUserUrl, withCredentials: true });

export const getSiteSettings = ({ sitename }) => {
  return instance.get(`${Config.baseUserUrl}/v1/tenant/site/${sitename}`);
};

export const getCustomers = async () => {
  try {
    const { data } = await instance.get('/v1/tenant?self=false');
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getRoles = () => {
  return instance.get('/v1/role');
};

export const getAccountUsers = (tenant: string) => {
  return instance.get(`/v1/user?filter[tenant]=${tenant}`);
};

export const createNewUser = (data) => {
  return instance.post('/v1/user', data);
};

export const updateUser = (data, id) => {
  return instance.put(`/v1/user/${id}`, data);
};

export const getGettingStartedInfo = () => {
  return instance.get('/v1/tenant/getting-started');
};

export const updateGettingStartedTask = (id, data) => {
  return instance.put(`/v1/tenant/getting-started/${id}`, data);
};

export const updateGettingStartedStatus = (data) => {
  return instance.put('/v1/tenant/getting-started', data);
};
